import { config } from 'aws-sdk'

import { environment } from '../../environments/environment'
import { authService } from './auth.service'

class AWSService {
  async initGlobalConfig(): Promise<void> {
    return new Promise((resolve) => {
      config.region = environment.aws.region

      authService.authSession.subscribe((authSession) => {
        if (authSession?.credentials)
          config.update({
            region: environment.aws.region,
            accessKeyId: authSession.credentials.accessKeyId,
            secretAccessKey: authSession.credentials.secretAccessKey,
            sessionToken: authSession.credentials.sessionToken
          })

        resolve()
      })
    })
  }
}

export const awsService = new AWSService()
