const TVOffIcon = () => {
  return (
    <svg
      style={{ maxWidth: '8rem' }}
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_2024_399" fill="white">
        <rect x="8" y="18" width="64" height="38" rx="2" />
      </mask>
      <rect
        x="8"
        y="18"
        width="64"
        height="38"
        rx="2"
        stroke="#A0ABBE"
        strokeWidth="8"
        strokeLinejoin="round"
        mask="url(#path-1-inside-1_2024_399)"
      />
      <path
        d="M28 62H52"
        stroke="#A0ABBE"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M35 61L35 55"
        stroke="#A0ABBE"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M45 61L45 55"
        stroke="#A0ABBE"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M50.9596 33.8912V36.4512H44.3804V33.8912H50.9596ZM45.6348 43.7216V33.6096C45.6348 32.7989 45.7798 32.1269 46.07 31.5936C46.3601 31.0603 46.7697 30.6613 47.2988 30.3968C47.8278 30.1323 48.4508 30 49.1676 30C49.6156 30 50.0508 30.0341 50.4732 30.1024C50.8998 30.1707 51.2156 30.2304 51.4204 30.2816L50.9084 32.816C50.7804 32.7776 50.631 32.7477 50.4604 32.7264C50.2897 32.7008 50.1404 32.688 50.0124 32.688C49.6796 32.688 49.4556 32.7584 49.3404 32.8992C49.2252 33.04 49.1676 33.2256 49.1676 33.456V43.7216H45.6348Z"
        fill="#A0ABBE"
      />
      <path
        d="M44.609 33.8912V36.4512H38.0298V33.8912H44.609ZM39.2842 43.7216V33.6096C39.2842 32.7989 39.4293 32.1269 39.7194 31.5936C40.0095 31.0603 40.4191 30.6613 40.9482 30.3968C41.4773 30.1323 42.1002 30 42.817 30C43.265 30 43.7002 30.0341 44.1226 30.1024C44.5493 30.1707 44.865 30.2304 45.0698 30.2816L44.5578 32.816C44.4298 32.7776 44.2805 32.7477 44.1098 32.7264C43.9391 32.7008 43.7898 32.688 43.6618 32.688C43.329 32.688 43.105 32.7584 42.9898 32.8992C42.8746 33.04 42.817 33.2256 42.817 33.456V43.7216H39.2842Z"
        fill="#A0ABBE"
      />
      <path
        d="M33.0944 43.9008C32.0277 43.9008 31.1147 43.6896 30.3552 43.2672C29.5957 42.8405 29.0133 42.2475 28.608 41.488C28.2027 40.7243 28 39.8389 28 38.832C28 37.8251 28.2027 36.9419 28.608 36.1824C29.0133 35.4187 29.5957 34.8256 30.3552 34.4032C31.1147 33.9765 32.0277 33.7632 33.0944 33.7632C34.1611 33.7632 35.0741 33.9765 35.8336 34.4032C36.5931 34.8256 37.1755 35.4187 37.5808 36.1824C37.9861 36.9419 38.1888 37.8251 38.1888 38.832C38.1888 39.8389 37.9861 40.7243 37.5808 41.488C37.1755 42.2475 36.5931 42.8405 35.8336 43.2672C35.0741 43.6896 34.1611 43.9008 33.0944 43.9008ZM33.12 41.2896C33.4187 41.2896 33.6768 41.1893 33.8944 40.9888C34.112 40.7883 34.2805 40.5024 34.4 40.1312C34.5195 39.76 34.5792 39.3184 34.5792 38.8064C34.5792 38.2901 34.5195 37.8485 34.4 37.4816C34.2805 37.1104 34.112 36.8245 33.8944 36.624C33.6768 36.4235 33.4187 36.3232 33.12 36.3232C32.8043 36.3232 32.5333 36.4235 32.3072 36.624C32.0811 36.8245 31.9083 37.1104 31.7888 37.4816C31.6693 37.8485 31.6096 38.2901 31.6096 38.8064C31.6096 39.3184 31.6693 39.76 31.7888 40.1312C31.9083 40.5024 32.0811 40.7883 32.3072 40.9888C32.5333 41.1893 32.8043 41.2896 33.12 41.2896Z"
        fill="#A0ABBE"
      />
    </svg>
  )
}

export default TVOffIcon
