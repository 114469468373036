/**
 * @description This enum reflects the values of `$transitionDuration` map in
 * `src/styles/transitions.scss`.
 */

export enum TransitionDuration {
  Fastest = 110,
  Fast = 150,
  Slow = 250,
  ReallySlow = 500
}
