const SmartSpeakerSpeakerIcon = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4 16.2949V10.1051C15.4 6.64541 15.4 4.91556 14.3821 4.48505C13.3643 4.05455 12.1663 5.27774 9.7705 7.72413C8.52978 8.99103 7.82188 9.27158 6.05659 9.27158C4.51278 9.27158 3.74088 9.27158 3.18638 9.6499C2.03534 10.4352 2.20933 11.9702 2.20933 13.2C2.20933 14.4298 2.03534 15.9648 3.18638 16.7501C3.74088 17.1285 4.51278 17.1285 6.05659 17.1285C7.82189 17.1285 8.52978 17.409 9.7705 18.6759C12.1663 21.1223 13.3643 22.3455 14.3821 21.915C15.4 21.4845 15.4 19.7546 15.4 16.2949Z"
        stroke="black"
        strokeWidth="1.65"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7 9.90002C19.3879 10.8017 19.8 11.9498 19.8 13.2C19.8 14.4502 19.3879 15.5984 18.7 16.5"
        stroke="black"
        strokeWidth="1.65"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 7.70007C23.3759 9.20281 24.2 11.1164 24.2 13.2001C24.2 15.2838 23.3759 17.1973 22 18.7001"
        stroke="black"
        strokeWidth="1.65"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SmartSpeakerSpeakerIcon
