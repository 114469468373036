const SpeakerOffIcon = () => {
  return (
    <svg
      style={{ maxWidth: '7.385rem' }}
      width="74"
      height="80"
      viewBox="0 0 74 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="37"
        cy="49"
        r="4"
        stroke="#A0ABBE"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <circle
        cx="37"
        cy="49"
        r="12"
        stroke="#A0ABBE"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <rect
        x="16"
        y="12"
        width="42"
        height="56"
        rx="2"
        stroke="#A0ABBE"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <path
        d="M47.9596 21.8912V24.4512H41.3804V21.8912H47.9596ZM42.6348 31.7216V21.6096C42.6348 20.7989 42.7798 20.1269 43.07 19.5936C43.3601 19.0603 43.7697 18.6613 44.2988 18.3968C44.8278 18.1323 45.4508 18 46.1676 18C46.6156 18 47.0508 18.0341 47.4732 18.1024C47.8998 18.1707 48.2156 18.2304 48.4204 18.2816L47.9084 20.816C47.7804 20.7776 47.631 20.7477 47.4604 20.7264C47.2897 20.7008 47.1404 20.688 47.0124 20.688C46.6796 20.688 46.4556 20.7584 46.3404 20.8992C46.2252 21.04 46.1676 21.2256 46.1676 21.456V31.7216H42.6348Z"
        fill="#A0ABBE"
      />
      <path
        d="M41.609 21.8912V24.4512H35.0298V21.8912H41.609ZM36.2842 31.7216V21.6096C36.2842 20.7989 36.4293 20.1269 36.7194 19.5936C37.0095 19.0603 37.4191 18.6613 37.9482 18.3968C38.4773 18.1323 39.1002 18 39.817 18C40.265 18 40.7002 18.0341 41.1226 18.1024C41.5493 18.1707 41.865 18.2304 42.0698 18.2816L41.5578 20.816C41.4298 20.7776 41.2805 20.7477 41.1098 20.7264C40.9391 20.7008 40.7898 20.688 40.6618 20.688C40.329 20.688 40.105 20.7584 39.9898 20.8992C39.8746 21.04 39.817 21.2256 39.817 21.456V31.7216H36.2842Z"
        fill="#A0ABBE"
      />
      <path
        d="M30.0944 31.9008C29.0277 31.9008 28.1147 31.6896 27.3552 31.2672C26.5957 30.8405 26.0133 30.2475 25.608 29.488C25.2027 28.7243 25 27.8389 25 26.832C25 25.8251 25.2027 24.9419 25.608 24.1824C26.0133 23.4187 26.5957 22.8256 27.3552 22.4032C28.1147 21.9765 29.0277 21.7632 30.0944 21.7632C31.1611 21.7632 32.0741 21.9765 32.8336 22.4032C33.5931 22.8256 34.1755 23.4187 34.5808 24.1824C34.9861 24.9419 35.1888 25.8251 35.1888 26.832C35.1888 27.8389 34.9861 28.7243 34.5808 29.488C34.1755 30.2475 33.5931 30.8405 32.8336 31.2672C32.0741 31.6896 31.1611 31.9008 30.0944 31.9008ZM30.12 29.2896C30.4187 29.2896 30.6768 29.1893 30.8944 28.9888C31.112 28.7883 31.2805 28.5024 31.4 28.1312C31.5195 27.76 31.5792 27.3184 31.5792 26.8064C31.5792 26.2901 31.5195 25.8485 31.4 25.4816C31.2805 25.1104 31.112 24.8245 30.8944 24.624C30.6768 24.4235 30.4187 24.3232 30.12 24.3232C29.8043 24.3232 29.5333 24.4235 29.3072 24.624C29.0811 24.8245 28.9083 25.1104 28.7888 25.4816C28.6693 25.8485 28.6096 26.2901 28.6096 26.8064C28.6096 27.3184 28.6693 27.76 28.7888 28.1312C28.9083 28.5024 29.0811 28.7883 29.3072 28.9888C29.5333 29.1893 29.8043 29.2896 30.12 29.2896Z"
        fill="#A0ABBE"
      />
    </svg>
  )
}

export default SpeakerOffIcon
