const BlindsOffIcon = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_2024_372" fill="white">
        <rect x="13" y="16" width="54" height="54" rx="2" />
      </mask>
      <rect
        x="13"
        y="16"
        width="54"
        height="54"
        rx="2"
        stroke="#A0ABBE"
        strokeWidth="8"
        strokeLinejoin="round"
        mask="url(#path-1-inside-1_2024_372)"
      />
      <mask id="path-2-inside-2_2024_372" fill="white">
        <rect x="19" y="22" width="42" height="42" rx="2" />
      </mask>
      <rect
        x="19"
        y="22"
        width="42"
        height="42"
        rx="2"
        stroke="#A0ABBE"
        strokeWidth="8"
        strokeLinejoin="round"
        mask="url(#path-2-inside-2_2024_372)"
      />
      <rect
        x="12"
        y="12"
        width="56"
        height="6"
        rx="3"
        stroke="#A0ABBE"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <path
        d="M50.7213 38.8912V41.4512H44.1421V38.8912H50.7213ZM45.3965 48.7216V38.6096C45.3965 37.7989 45.5416 37.1269 45.8317 36.5936C46.1218 36.0603 46.5314 35.6613 47.0605 35.3968C47.5896 35.1323 48.2125 35 48.9293 35C49.3773 35 49.8125 35.0341 50.2349 35.1024C50.6616 35.1707 50.9773 35.2304 51.1821 35.2816L50.6701 37.816C50.5421 37.7776 50.3928 37.7477 50.2221 37.7264C50.0514 37.7008 49.9021 37.688 49.7741 37.688C49.4413 37.688 49.2173 37.7584 49.1021 37.8992C48.9869 38.04 48.9293 38.2256 48.9293 38.456V48.7216H45.3965Z"
        fill="#A0ABBE"
      />
      <path
        d="M44.3707 38.8912V41.4512H37.7915V38.8912H44.3707ZM39.0459 48.7216V38.6096C39.0459 37.7989 39.191 37.1269 39.4811 36.5936C39.7712 36.0603 40.1808 35.6613 40.7099 35.3968C41.239 35.1323 41.8619 35 42.5787 35C43.0267 35 43.4619 35.0341 43.8843 35.1024C44.311 35.1707 44.6267 35.2304 44.8315 35.2816L44.3195 37.816C44.1915 37.7776 44.0422 37.7477 43.8715 37.7264C43.7008 37.7008 43.5515 37.688 43.4235 37.688C43.0907 37.688 42.8667 37.7584 42.7515 37.8992C42.6363 38.04 42.5787 38.2256 42.5787 38.456V48.7216H39.0459Z"
        fill="#A0ABBE"
      />
      <path
        d="M32.8561 48.9008C31.7895 48.9008 30.8764 48.6896 30.1169 48.2672C29.3575 47.8405 28.7751 47.2475 28.3697 46.488C27.9644 45.7243 27.7617 44.8389 27.7617 43.832C27.7617 42.8251 27.9644 41.9419 28.3697 41.1824C28.7751 40.4187 29.3575 39.8256 30.1169 39.4032C30.8764 38.9765 31.7895 38.7632 32.8561 38.7632C33.9228 38.7632 34.8359 38.9765 35.5953 39.4032C36.3548 39.8256 36.9372 40.4187 37.3425 41.1824C37.7479 41.9419 37.9505 42.8251 37.9505 43.832C37.9505 44.8389 37.7479 45.7243 37.3425 46.488C36.9372 47.2475 36.3548 47.8405 35.5953 48.2672C34.8359 48.6896 33.9228 48.9008 32.8561 48.9008ZM32.8817 46.2896C33.1804 46.2896 33.4385 46.1893 33.6561 45.9888C33.8737 45.7883 34.0423 45.5024 34.1617 45.1312C34.2812 44.76 34.3409 44.3184 34.3409 43.8064C34.3409 43.2901 34.2812 42.8485 34.1617 42.4816C34.0423 42.1104 33.8737 41.8245 33.6561 41.624C33.4385 41.4235 33.1804 41.3232 32.8817 41.3232C32.566 41.3232 32.2951 41.4235 32.0689 41.624C31.8428 41.8245 31.67 42.1104 31.5505 42.4816C31.4311 42.8485 31.3713 43.2901 31.3713 43.8064C31.3713 44.3184 31.4311 44.76 31.5505 45.1312C31.67 45.5024 31.8428 45.7883 32.0689 45.9888C32.2951 46.1893 32.566 46.2896 32.8817 46.2896Z"
        fill="#A0ABBE"
      />
    </svg>
  )
}

export default BlindsOffIcon
