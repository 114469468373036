import {
  ILocalStorageItems,
  LocalStorageKeys
} from './local-storage.service.types'

/**
 * @description This service provides an additional layer around the
 * `window.localStorage` methods to make its keys typed and include it's type
 * checking during the TypeScript compilation process to avoid typos.
 */
class LocalStorageService {
  setItem(item: ILocalStorageItems): void {
    const [key, value] = Object.entries(item)[0]
    window.localStorage.setItem(key, JSON.stringify(value))
  }

  getItem(key: LocalStorageKeys): string | null {
    return window.localStorage.getItem(key)
  }

  removeItem(key: LocalStorageKeys): void {
    window.localStorage.removeItem(key)
  }

  key(index: number): string | null {
    return window.localStorage.key(index)
  }
}

export const localStorageService = new LocalStorageService()
