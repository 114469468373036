import { translate } from './language.service'

class UtilsService {
  getHumanReadableDate(date: number): string {
    const d = new Date(date)

    return `${translate.common.months[d.getMonth()].slice(
      0,
      3
    )} ${d.getDate()}, ${d.getFullYear()}`
  }

  getHumanReadableTime(date: number): string {
    const d = new Date(date)
    const getTwoDigitValue = (value: number) =>
      `${String(value).length === 1 ? `0${value}` : value}`

    return `${getTwoDigitValue(d.getHours())}:${getTwoDigitValue(
      d.getMinutes()
    )}`
  }
}

export const utilsService = new UtilsService()
