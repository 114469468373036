const HVACHeatingIndicatorTwoIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.40831 10.45C9.48578 10.3719 9.57795 10.3099 9.6795 10.2676C9.78105 10.2253 9.88997 10.2035 9.99998 10.2035C10.11 10.2035 10.2189 10.2253 10.3205 10.2676C10.422 10.3099 10.5142 10.3719 10.5916 10.45L13.0916 12.95C13.1715 13.0277 13.2353 13.1204 13.2794 13.2228C13.3235 13.3251 13.347 13.4352 13.3485 13.5466C13.3501 13.658 13.3296 13.7687 13.2884 13.8722C13.2472 13.9757 13.186 14.0702 13.1083 14.15C13.0306 14.2299 12.9379 14.2937 12.8356 14.3378C12.7332 14.3819 12.6232 14.4054 12.5118 14.4069C12.4003 14.4084 12.2897 14.388 12.1861 14.3468C12.0826 14.3056 11.9882 14.2444 11.9083 14.1667L9.99998 12.2167L8.09164 14.1667C7.9303 14.3236 7.71324 14.41 7.48819 14.4069C7.26315 14.4038 7.04856 14.3114 6.89164 14.15C6.73472 13.9887 6.64832 13.7716 6.65145 13.5466C6.65457 13.3215 6.74697 13.107 6.90831 12.95L9.40831 10.45ZM11.9083 9.55004L9.99998 7.63337L8.09164 9.55004C7.9303 9.70696 7.71324 9.79336 7.48819 9.79023C7.26315 9.78711 7.04856 9.69471 6.89164 9.53337C6.73472 9.37203 6.64832 9.15496 6.65145 8.92992C6.65457 8.70488 6.74697 8.49029 6.90831 8.33337L9.40831 5.83337C9.48578 5.75527 9.57795 5.69327 9.6795 5.65096C9.78105 5.60866 9.88997 5.58687 9.99998 5.58687C10.11 5.58687 10.2189 5.60866 10.3205 5.65096C10.422 5.69327 10.5142 5.75527 10.5916 5.83337L13.0916 8.33337C13.2486 8.49029 13.3367 8.70312 13.3367 8.92504C13.3367 9.14696 13.2486 9.35979 13.0916 9.51671C12.9347 9.67363 12.7219 9.76178 12.5 9.76178C12.2781 9.76178 12.0652 9.67363 11.9083 9.51671V9.55004Z"
        fill="#FFA800"
      />
    </svg>
  )
}

export default HVACHeatingIndicatorTwoIcon
