import { EN, ILanguage } from '../../languages'
import { Language } from '../enums'

/**
 *
 */
class LanguageService {
  currentLanguage: null | Language = null

  setLanguage(): void {
    this.currentLanguage = Language.EN
  }

  /**
   * @description Translates the given key.
   */
  get translate(): ILanguage {
    switch (this.currentLanguage) {
      case Language.EN:
        return EN
      default:
        return EN
    }
  }
}

const languageService = new LanguageService()
const translate = languageService.translate

export { languageService, translate }
