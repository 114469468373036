import { useRef, useEffect, useState, useCallback, MouseEvent } from 'react'
import { Subscription, Subject, fromEvent, takeUntil } from 'rxjs'

import clsx from 'clsx'

import classes from './slider.module.scss'

interface Props {
  value: undefined | number
  min: number
  max: number
  disabled?: boolean
  version?: 'thin' | 'thick'
  thumbColor?: string
  label?: string
  valueLabel?: string
  levels?: { level: string; className?: string }[]
  onChange?: (value: number) => void
  onStableValue?: (value: number) => void
  onMouseup?: (event: MouseEvent) => void
  className?: string
}

const Slider = ({
  value,
  min,
  max,
  disabled,
  version = 'thin',
  thumbColor,
  label,
  valueLabel,
  levels,
  onChange,
  onStableValue,
  onMouseup,
  className
}: Props) => {
  const lineAndThumbContainerElement = useRef<null | HTMLDivElement>(null)
  const lineElement = useRef<null | HTMLDivElement>(null)
  const thumbElement = useRef<null | HTMLDivElement>(null)
  const mousemoveSubscription = useRef<Subscription>()
  const isTouchDevice = useRef(navigator.maxTouchPoints > 0)
  const previousValue = useRef(value)
  const touchStarted = useRef(false)
  const readyToTriggerMouseup = useRef(false)
  const lastChange = useRef(0)
  const stableValueTimeoutId = useRef<NodeJS.Timeout>()
  const [thumbXCoordinate, setThumbXCoordinate] = useState(0)
  const mouseEventsRegistered = useRef(
    new Map<'mousedown' | 'mouseup' | 'resize', boolean>()
  )
  const $destroyed = useRef(new Subject<void>())

  const handleOnStableValue = useCallback(
    (currentValue: number) => {
      const TIMEOUT_DURATION = 100

      if (
        stableValueTimeoutId.current &&
        new Date().getTime() - lastChange.current < TIMEOUT_DURATION
      )
        clearTimeout(stableValueTimeoutId.current)

      if (onStableValue) onStableValue(currentValue)

      lastChange.current = new Date().getTime()
    },
    [onStableValue]
  )

  const handleOnChange = useCallback(
    (currentValue: number) => {
      if (onChange && previousValue.current !== currentValue) {
        readyToTriggerMouseup.current = true
        previousValue.current = currentValue
        onChange(currentValue)
        handleOnStableValue(currentValue)
      }
    },
    [onChange, handleOnStableValue]
  )

  const calculateThumbCoordinate = useCallback(
    (clientX?: number, thumbMoved = false) => {
      if (typeof value !== 'undefined' || typeof clientX !== 'undefined') {
        const lineElementStyles = window.getComputedStyle(lineElement.current!)
        const lineElementWidth = +lineElementStyles.width.split('px')[0]
        const lineInterval = lineElementWidth / (max - min)
        const getCurrentValueFromCoordinate = (coordinate: number) =>
          Math.round(coordinate / lineInterval) + min
        const getThumbCoordinate = (_clientX?: number, _value?: number) => {
          if (_clientX)
            return _clientX - lineElement.current!.getBoundingClientRect().x
          else if (_value) return (_value - min) * lineInterval
        }
        const getIntervalsCountFromCoordinate = (coordinate: number) =>
          Math.floor(coordinate / lineInterval)
        const getTransformedThumbCoordinate = (): number => {
          let _thumbCoordinate = thumbMoved
            ? getThumbCoordinate(clientX)
            : getThumbCoordinate(undefined, value)

          if (typeof _thumbCoordinate !== 'undefined') {
            const currentValueFromCoordinate =
              getCurrentValueFromCoordinate(_thumbCoordinate)

            if (currentValueFromCoordinate <= min) _thumbCoordinate = 0
            else if (currentValueFromCoordinate > max)
              _thumbCoordinate = lineElementWidth
          }

          return _thumbCoordinate!
        }
        const thumbCoordinate = getTransformedThumbCoordinate()

        if (typeof thumbCoordinate !== 'undefined') {
          let intervalsCount = getIntervalsCountFromCoordinate(thumbCoordinate)

          if (thumbMoved && thumbCoordinate % lineInterval > lineInterval / 2)
            intervalsCount += 1

          setThumbXCoordinate(intervalsCount * lineInterval)
          handleOnChange(getCurrentValueFromCoordinate(thumbCoordinate))
        }
      }
    },
    [max, min, value, handleOnChange]
  )

  const setThickVersionThumbColor = useCallback(() => {
    if (value) {
      const colors = ['#ffa800', '#ffd600', '#f7f7f7', '#339dea', '#3371ea']
      const singleColorRange = (max - min) / colors.length
      const colorRangesMappedToColors: [string, number][] = colors.map(
        (color, i) => [color, min + singleColorRange * (i + 1)]
      )

      for (const [color, colorRange] of colorRangesMappedToColors)
        if (value < colorRange) {
          thumbElement.current!.style.backgroundColor = color
          break
        }
    }
  }, [min, max, value])

  useEffect(() => {
    calculateThumbCoordinate()
    // Avoid flickering effect initially
    thumbElement.current!.style.opacity = '1'

    $destroyed.current.next()
    $destroyed.current.complete()
    $destroyed.current = new Subject<void>()

    if (!mouseEventsRegistered.current.get('mousedown')) {
      mouseEventsRegistered.current.set('mousedown', true)

      fromEvent<MouseEvent>(
        lineAndThumbContainerElement.current!,
        isTouchDevice.current ? 'touchstart' : 'mousedown'
      )
        .pipe(takeUntil($destroyed.current))
        .subscribe(() => {
          if (disabled) return
          touchStarted.current = true

          mousemoveSubscription.current = fromEvent<MouseEvent | TouchEvent>(
            window.document.body,
            isTouchDevice.current ? 'touchmove' : 'mousemove'
          ).subscribe((event) => {
            let clientX: number

            clientX = isTouchDevice.current
              ? (event as TouchEvent).touches[0].clientX
              : (event as MouseEvent).clientX

            calculateThumbCoordinate(clientX, true)
          })
        })
    }

    if (!mouseEventsRegistered.current.get('mouseup')) {
      mouseEventsRegistered.current.set('mouseup', true)
      fromEvent<MouseEvent>(
        window.document,
        isTouchDevice.current ? 'touchend' : 'mouseup'
      )
        .pipe(takeUntil($destroyed.current))
        .subscribe((event) => {
          mousemoveSubscription.current?.unsubscribe()

          if (
            onMouseup &&
            touchStarted.current &&
            readyToTriggerMouseup.current
          ) {
            touchStarted.current = false
            readyToTriggerMouseup.current = false
            onMouseup(event)
          }
        })
    }

    if (!mouseEventsRegistered.current.get('resize')) {
      mouseEventsRegistered.current.set('resize', true)

      fromEvent<Event>(window, 'resize')
        .pipe(takeUntil($destroyed.current))
        .subscribe(() => calculateThumbCoordinate())
    }

    return () => {
      $destroyed.current.next()
      // eslint-disable-next-line react-hooks/exhaustive-deps
      $destroyed.current.complete()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled])

  useEffect(() => {
    calculateThumbCoordinate()
  }, [value, calculateThumbCoordinate])

  useEffect(() => {
    if (version === 'thick') setThickVersionThumbColor()
  }, [version, value, setThickVersionThumbColor])

  return (
    <div
      className={`${classes.rootContainer} ${className} ${clsx({
        [classes.thin]: version === 'thin',
        [classes.thick]: version === 'thick'
      })}`}
    >
      {(typeof label !== 'undefined' || typeof valueLabel !== 'undefined') && (
        <ul className={`${classes.labelsContainer} no-select`}>
          <li>{label ?? ''}</li>
          <li>{valueLabel ?? ''}</li>
        </ul>
      )}

      <div
        className={classes.lineAndThumbContainer}
        ref={lineAndThumbContainerElement}
      >
        <div className={classes.line} ref={lineElement}></div>
        <div className={classes.thumbContainer}>
          <div
            className={classes.thumb}
            ref={thumbElement}
            style={{
              left: `${thumbXCoordinate}px`,
              backgroundColor: thumbColor
            }}
            draggable={false}
          ></div>
        </div>
      </div>

      <ul className={classes.levelsContainer}>
        {levels?.map((level, i) => (
          <li className={`no-select ${level.className}`} key={i}>
            {level.level}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Slider
