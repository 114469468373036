import FooterIcons from '../../icons/footer-icons/footer-icons'
import classes from './footer.module.scss'

const Footer = () => {
  return (
    <footer className={classes.rootContainer}>
      <FooterIcons />
    </footer>
  )
}

export default Footer
