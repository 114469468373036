import { BehaviorSubject } from 'rxjs'
import { Iot } from 'aws-sdk'
import { device } from 'aws-iot-device-sdk'

import { environment } from '../../environments/environment'
import { authService } from './auth.service'
import {
  DevicePowerStatus,
  DeviceType,
  HVACStatus,
  SmartBlindPosition,
  SmartKettleStatus
} from '../enums'
import {
  IBackendDevicesKeys,
  IDevice,
  IPartialBackendDevices,
  IChatMessage,
  IChatCommand
} from './iot.service.types'

/**
 * `AWSIoTData` is defined in `public/aws-iot-device-sdk.js`
 */
declare const AWSIoTData: { device: typeof device }

class IoTService {
  $devices = new BehaviorSubject<IDevice[]>([
    {
      id: 'SmartBlinds',
      name: 'Smart Blinds',
      powerStatus: DevicePowerStatus.Off,
      type: DeviceType.SmartBlinds,
      position: 0
    },
    {
      id: 'SmartLightBulb',
      name: 'Smart Lightbulb',
      powerStatus: DevicePowerStatus.Off,
      type: DeviceType.SmartLightbulb,
      brightness: 0,
      colorTemperature: 2700
    },
    {
      id: 'SmartTV',
      name: 'Smart TV',
      powerStatus: DevicePowerStatus.Off,
      type: DeviceType.SmartTV,
      volume: 0,
      currentChannel: ''
    },
    {
      id: 'SmartSpeaker',
      name: 'Smart Speaker',
      powerStatus: DevicePowerStatus.Off,
      type: DeviceType.SmartSpeaker,
      volume: 0,
      currentMedia: ''
    },
    {
      id: 'SmartKettle',
      name: 'Smart Kettle',
      powerStatus: DevicePowerStatus.Off,
      type: DeviceType.SmartKettle,
      targetTemperature: 0,
      status: SmartKettleStatus.Off
    },
    {
      id: 'HVAC',
      name: 'HVAC',
      powerStatus: DevicePowerStatus.Off,
      type: DeviceType.HVAC,
      temperatureSetpoint: 0,
      status: undefined
    }
  ])
  $chatMessages = new BehaviorSubject<IChatMessage[]>([])
  $chatCommand = new BehaviorSubject<IChatMessage>({ message: '' })
  hvacDeviceStatusMapToNumbers: Record<HVACStatus, number> = {
    [HVACStatus.Cooling]: 1,
    [HVACStatus.Eco]: 2,
    [HVACStatus.Heating]: 3
  }
  hvacNumbersMapToDeviceStatus: Record<number, HVACStatus> = {
    1: HVACStatus.Cooling,
    2: HVACStatus.Eco,
    3: HVACStatus.Heating
  }

  attachPrincipalPolicyToIot(policyName: string, principal: string): void {
    new Iot().attachPrincipalPolicy({ policyName, principal }, (error) => {
      if (error) console.error(error)
    })
  }

  getDevice<T>(
    topicName: string,
    $devicesSubject?: BehaviorSubject<T>
  ): undefined | device {
    const authSession = authService.authSession.value

    if (authSession?.identityId && authSession?.credentials) {
      this.attachPrincipalPolicyToIot(
        environment.aws.iot.policyName,
        authSession.identityId
      )

      const device = new AWSIoTData.device({
        region: environment.aws.region,
        host: environment.aws.iot.endpoint,
        clientId: `mqtt-espdemo-'${Math.floor(Math.random() * 1e5 + 1)}`,
        protocol: 'wss',
        maximumReconnectTimeMs: 8e5,
        debug: false,
        accessKeyId: authSession.credentials.accessKeyId,
        secretKey: authSession.credentials.secretAccessKey,
        sessionToken: authSession.credentials.sessionToken
      })

      device.subscribe(topicName)

      device.on('error', (error: any) => {
        console.error('error', error)
        if ($devicesSubject) $devicesSubject.error(error)
      })

      return device
    }
  }

  transformBackendDevicesData(
    updatedDevices: IPartialBackendDevices
  ): IDevice[] {
    Object.entries(updatedDevices).forEach(([key, updatedDevice]) => {
      const updatedDeviceId = key as IBackendDevicesKeys
      const foundDevice = this.$devices.value.find(
        ({ id }) => id === updatedDeviceId
      )

      if (foundDevice)
        Object.keys(updatedDevice).forEach((key) => {
          ;(foundDevice as any)[key] = (updatedDevice as any)[key]
        })
    })

    return this.$devices.value
  }

  deviceChanges(): BehaviorSubject<IDevice[]> {
    const $devicesSubject = new BehaviorSubject<IDevice[]>(this.$devices.value)
    const device = this.getDevice<IDevice[]>(
      environment.aws.iot.deviceTopicName,
      $devicesSubject
    )

    if (device)
      device.on('message', (topic: string, payload: Uint8Array) => {
        const backendDevices: IPartialBackendDevices = JSON.parse(
          payload.toString()
        )

        $devicesSubject.next(this.transformBackendDevicesData(backendDevices))
      })

    return $devicesSubject
  }

  chatMessageChanges(): BehaviorSubject<IChatMessage[]> {
    const deviceMessages = this.getDevice<IChatMessage[]>(
      environment.aws.iot.chatMessageTopicName,
      this.$chatMessages
    )
    const deviceErrorMessages = this.getDevice<IChatMessage[]>(
      environment.aws.iot.chatErrorTopicName,
      this.$chatMessages
    )
    const receivedMessageHandlerCb = (topic: string, payload: Uint8Array) => {
      const chatMessage: IChatMessage = JSON.parse(payload.toString())
      this.$chatMessages.next([chatMessage, ...this.$chatMessages.value])
    }

    if (deviceMessages && deviceErrorMessages) {
      deviceMessages.on('message', receivedMessageHandlerCb)
      deviceErrorMessages.on('message', receivedMessageHandlerCb)
    }

    return this.$chatMessages
  }

  chatCommandChanges(): BehaviorSubject<IChatCommand> {
    const $chatCommand = new BehaviorSubject<IChatCommand>(
      this.$chatCommand.value
    )
    const device = this.getDevice<IChatCommand>(
      environment.aws.iot.chatCommandTopicName,
      $chatCommand
    )

    if (device)
      device.on('message', (topic: string, payload: Uint8Array) => {
        const chatCommand: IChatCommand = JSON.parse(payload.toString())
        $chatCommand.next(chatCommand)
      })

    return $chatCommand
  }

  sendChatCommand(chatCommand: IChatCommand): void {
    const device = this.getDevice<IChatCommand>(
      environment.aws.iot.chatSendCommandTopicName
    )
    device?.publish(
      environment.aws.iot.chatSendCommandTopicName,
      JSON.stringify(chatCommand)
    )
  }

  convertContinuesValueToDiscreteValue(
    min: number,
    max: number,
    currentValue?: number
  ): SmartBlindPosition {
    if (!currentValue) return min
    const singleUnit = 100 / max
    return Math.ceil(currentValue / singleUnit)
  }

  convertSmartBlindPositionToContinuesValue(
    min: number,
    max: number,
    currentValue?: SmartBlindPosition
  ): SmartBlindPosition {
    if (!currentValue) return min

    const singleUnit = 100 / max
    return Math.floor(currentValue * singleUnit)
  }

  convertHVACDeviceStatusToDiscreteValue(
    status: undefined | HVACStatus
  ): number {
    if (!status) return this.hvacDeviceStatusMapToNumbers[HVACStatus.Cooling]
    return this.hvacDeviceStatusMapToNumbers[status]
  }
}

export const iotService = new IoTService()
