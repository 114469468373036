import { NavigateFunction } from 'react-router-dom'
import { BehaviorSubject } from 'rxjs'
import { Amplify } from 'aws-amplify'
import {
  signIn,
  SignInOutput,
  confirmSignIn,
  ConfirmSignInOutput,
  fetchAuthSession,
  fetchUserAttributes,
  FetchUserAttributesOutput
} from 'aws-amplify/auth'
import { AuthSession } from '@aws-amplify/core/dist/esm/singleton/Auth/types'

import { ISignInPayload } from './amplify.service.types'
import { localStorageService } from './local-storage.service'

import { ILocalStorageItems } from './local-storage.service.types'
import { environment } from '../../environments/environment'

class AuthService {
  readonly authSession = new BehaviorSubject<
    null | ILocalStorageItems['authSession']
  >(null)
  refreshTokenTimeoutId: undefined | NodeJS.Timeout = undefined

  constructor() {
    this.setAuthSessionFromLocalStorage()
    setInterval(() => this.refreshToken(), 2e3)
  }

  async refreshToken(): Promise<void> {
    if (this.authSession.value) await this.fetchAndSetAuthSession()
  }

  setAuthSessionFromLocalStorage(): void {
    const authSessionStr = localStorageService.getItem('authSession')

    if (authSessionStr) {
      const authSession: AuthSession = JSON.parse(authSessionStr)
      this.authSession.next(authSession)
    } else this.removeCognitoCredentials()
  }

  async removeCognitoCredentials(): Promise<void> {
    return new Promise(async (resolve) => {
      const removeCognitoKeywordLocalStorageKeys = () =>
        new Promise((resolve) => {
          const localStorageKeysLength = window.localStorage.length

          for (let i = 0; i < localStorageKeysLength; i++) {
            const key: any = localStorageService.key(i)

            setTimeout(() => {
              if (key?.toLowerCase()?.includes('cognito'))
                localStorageService.removeItem(key)
              if (i === localStorageKeysLength - 1) resolve(undefined)
            })
          }
        })

      await removeCognitoKeywordLocalStorageKeys()
      localStorageService.removeItem('authSession')
      this.authSession.next(null)
      resolve()
    })
  }

  async initGlobalConfig(): Promise<void> {
    return new Promise(async (resolve) => {
      Amplify.configure({
        ...Amplify.getConfig(),
        Auth: {
          Cognito: {
            identityPoolId: environment.aws.cognito.identityPoolId,
            allowGuestAccess: false,
            userPoolClientId: environment.aws.cognito.userPoolClientId,
            userPoolId: environment.aws.cognito.userPoolId
          }
        }
      })
      await this.fetchAuthSession()
      resolve()
    })
  }

  signIn(signInInput: ISignInPayload): Promise<SignInOutput> {
    return signIn(signInInput).then(async (response) => {
      if (
        response.nextStep.signInStep ===
        'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
      )
        response = await this.confirmSignIn(signInInput.password)
      if (response.isSignedIn) this.fetchAndSetAuthSession()

      return response
    })
  }

  async fetchAndSetAuthSession(): Promise<void> {
    const authSession = await this.fetchAuthSession()
    localStorageService.setItem({ authSession })
    this.authSession.next(authSession)
  }

  confirmSignIn(
    password: ISignInPayload['password']
  ): Promise<ConfirmSignInOutput> {
    return confirmSignIn({
      challengeResponse: password
    })
  }

  fetchAuthSession(): Promise<AuthSession> {
    return fetchAuthSession()
  }

  fetchUserAttributes(): Promise<FetchUserAttributesOutput> {
    return fetchUserAttributes()
  }

  async logout(navigate?: NavigateFunction): Promise<void> {
    await this.removeCognitoCredentials()

    if (navigate)
      import('../enums/route-paths').then(({ AuthRoutePaths }) =>
        navigate(AuthRoutePaths.Login)
      )
    else window.location.reload()
  }
}

export const authService = new AuthService()
