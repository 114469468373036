import { Amplify } from 'aws-amplify'
import { uploadData } from 'aws-amplify/storage'

import { environment } from '../../environments/environment'

class AmplifyService {
  initGlobalConfig(): Promise<void> {
    return new Promise((resolve) => {
      Amplify.configure({
        ...Amplify.getConfig(),
        Storage: {
          S3: {
            bucket: environment.aws.s3.audioFilesBucketName,
            region: environment.aws.region
          }
        }
      })

      resolve()
    })
  }

  uploadAudioFileToS3(audioChunks: Blob[]): Promise<any> {
    return uploadData({
      key: `user-command-${Math.floor(Math.random() * 1e7) + 1}.webm`,
      data: new Blob(audioChunks, { type: 'audio/webm' }),
      options: {
        accessLevel: 'private',
        contentType: 'audio/webm'
      }
    }).result
  }
}

export const amplifyService = new AmplifyService()
