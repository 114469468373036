import { useEffect } from 'react'
import {
  useLocation,
  Routes,
  Route,
  Navigate,
  useNavigate
} from 'react-router-dom'

import Header from './shared/components/header/header'
import Devices from './devices/devices'
import Login from './auth/login/login'
import Footer from './shared/components/footer/footer'

import {
  PAGE_TITLES,
  RoutePaths,
  AuthRoutePaths,
  DeviceRoutePaths,
  WildCardRoutePaths,
  WILD_CARD_MATCH_ROUTE_PATH
} from './shared/enums/route-paths'
import { authService } from './shared/services'

import './App.scss'
import { fromEvent } from 'rxjs'

function App() {
  const location = useLocation()
  const navigate = useNavigate()

  const setHTMLTagFontSize = () => {
    window.document.documentElement.style.fontSize = `${
      0.007007708479327 * window.innerWidth
    }px`
  }

  useEffect(() => {
    if (!authService.authSession.value) navigate(AuthRoutePaths.Login)
    else navigate(DeviceRoutePaths.Devices)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    document.title = PAGE_TITLES[window.location.pathname as RoutePaths]
  }, [location])

  useEffect(() => {
    setHTMLTagFontSize()
    fromEvent(window, 'resize').subscribe(setHTMLTagFontSize)
  }, [])

  return (
    <div className="rootContainer">
      <Header />

      <main>
        <Routes>
          <Route path={AuthRoutePaths.Login} Component={Login} />
          <Route path={DeviceRoutePaths.Devices} Component={Devices} />
          <Route
            path={WildCardRoutePaths.Any}
            element={<Navigate to={WILD_CARD_MATCH_ROUTE_PATH} />}
          />
        </Routes>
      </main>

      <Footer />
    </div>
  )
}

export default App
