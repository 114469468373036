class AudioAnalyserService {
  subscribedToSilence = false
  silenceAudioStream: null | MediaStream = null

  async subscribeOnSilence({
    onSilence,
    onSpeaking
  }: {
    onSilence?: () => void
    onSpeaking?: () => void
  }): Promise<void> {
    if (!this.subscribedToSilence) {
      this.subscribedToSilence = true
      const ctx = new AudioContext()
      const analyser = ctx.createAnalyser()
      this.silenceAudioStream = await navigator.mediaDevices.getUserMedia({
        audio: true
      })
      const streamNode = ctx.createMediaStreamSource(this.silenceAudioStream)

      streamNode.connect(analyser)
      analyser.minDecibels = -60

      const data = new Uint8Array(analyser.frequencyBinCount) // will hold our data
      let silenceStart = performance.now()
      let isSilent = true // Trigger only once per silence event

      const loop = (time: number = silenceStart) => {
        if (this.subscribedToSilence) requestAnimationFrame(loop) // We'll loop every 60th of a second to check
        analyser.getByteFrequencyData(data) // Get current data
        // If there is data above the given db limit
        if (data.some((v) => v)) {
          if (onSpeaking) onSpeaking()

          isSilent = false
          silenceStart = time // set it to now
        }

        if (!isSilent && time - silenceStart > 500) {
          if (onSilence) onSilence()
          isSilent = true
        }
      }

      loop()
    }
  }

  unsubscribeFromSilence(): void {
    this.subscribedToSilence = false
    this.silenceAudioStream!.getTracks().forEach((track) => track.stop())
  }
}

export const audioAnalyserService = new AudioAnalyserService()
