import { ReactNode, useRef, useState, useEffect } from 'react'
import classes from './fade-animation.module.scss'

interface Props {
  forceRerender: any
  children: ReactNode
  className?: string
}

const FadeAnimation = ({ forceRerender, children, className }: Props) => {
  const rootContainerElement = useRef<null | HTMLDivElement>(null)
  const initialRenderId = useRef(forceRerender)
  const [nextRenderId, setNextRenderId] = useState(forceRerender)

  useEffect(() => {
    setNextRenderId(forceRerender)

    if (
      rootContainerElement.current &&
      initialRenderId.current !== nextRenderId
    )
      rootContainerElement.current.style.opacity = '0'

    setTimeout(() => {
      if (rootContainerElement.current)
        rootContainerElement.current.style.opacity = '1'
    }, 100)
  }, [forceRerender, nextRenderId])

  return (
    <div
      className={`${classes.rootContainer} ${className}`}
      ref={rootContainerElement}
    >
      {children}
    </div>
  )
}

export default FadeAnimation
