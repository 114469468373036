import { ProcessEnv } from '../shared/types/node-js'
import { IEnvironment } from './environment.types'

const env = process.env as unknown as ProcessEnv

/**
 * This variable can be used throughout the application to use environment
 * variable values.
 */
export const environment: IEnvironment = {
  aws: {
    region: env.REACT_APP_AWS_REGION,
    cognito: {
      identityPoolId: env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
      userPoolId: env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
      userPoolClientId: env.REACT_APP_AWS_COGNITO_USER_POOL_CLIENT_ID
    },
    iot: {
      endpoint: env.REACT_APP_AWS_IOT_ENDPOINT,
      policyName: env.REACT_APP_AWS_IOT_POLICY_NAME,
      deviceTopicName: env.REACT_APP_AWS_IOT_DEVICE_TOPIC_NAME,
      chatMessageTopicName: env.REACT_APP_AWS_IOT_CHAT_MESSAGE_TOPIC_NAME,
      chatErrorTopicName: env.REACT_APP_AWS_IOT_CHAT_ERROR_TOPIC_NAME,
      chatCommandTopicName: env.REACT_APP_AWS_IOT_CHAT_COMMAND_TOPIC_NAME,
      chatSendCommandTopicName:
        env.REACT_APP_AWS_IOT_CHAT_SEND_COMMAND_TOPIC_NAME
    },
    s3: {
      audioFilesBucketName: env.REACT_APP_AWS_AUDIO_FILES_BUCKET_NAME
    }
  }
}
