import { ReactNode, MouseEvent } from 'react'
import clsx from 'clsx'

import classes from './button.module.scss'

interface Props {
  children: ReactNode
  playPulseAnimation?: boolean
  onClick?: (event: MouseEvent) => void
  disabled?: boolean
}

const Button = ({ children, playPulseAnimation, onClick, disabled }: Props) => {
  return (
    <button
      className={`${classes.button} ${clsx({
        [classes.playPulseAnimation]: playPulseAnimation
      })}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default Button
