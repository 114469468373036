const TVOnIcon = () => {
  return (
    <svg
      style={{ maxWidth: '8rem' }}
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_2024_393" fill="white">
        <rect x="8" y="18" width="64" height="38" rx="2" />
      </mask>
      <rect
        x="8"
        y="18"
        width="64"
        height="38"
        rx="2"
        stroke="#FF007B"
        strokeWidth="8"
        strokeLinejoin="round"
        mask="url(#path-1-inside-1_2024_393)"
      />
      <path
        d="M28 62H52"
        stroke="#FF007B"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M35 61L35 55"
        stroke="#FF007B"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M45 61L45 55"
        stroke="#FF007B"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default TVOnIcon
