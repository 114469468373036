const LampOffIcon = () => {
  return (
    <svg
      style={{ maxWidth: '8rem' }}
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35 62H47"
        stroke="#A0ABBE"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M38 69H44"
        stroke="#A0ABBE"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M60 32C60 38.7534 56.4679 43.8932 50.9569 47.194C49.7673 47.9065 48.9459 49.2051 48.9459 50.6943V54.5H41H33.0541L33.0541 50.6943C33.0541 49.2051 32.2327 47.9065 31.0431 47.194C25.5321 43.8932 22 38.7534 22 32C22 21.5066 30.5066 13 41 13C51.4934 13 60 21.5066 60 32Z"
        stroke="#A0ABBE"
        strokeWidth="4"
      />
      <path
        d="M51.9596 27.8912V30.4512H45.3804V27.8912H51.9596ZM46.6348 37.7216V27.6096C46.6348 26.7989 46.7798 26.1269 47.07 25.5936C47.3601 25.0603 47.7697 24.6613 48.2988 24.3968C48.8278 24.1323 49.4508 24 50.1676 24C50.6156 24 51.0508 24.0341 51.4732 24.1024C51.8998 24.1707 52.2156 24.2304 52.4204 24.2816L51.9084 26.816C51.7804 26.7776 51.631 26.7477 51.4604 26.7264C51.2897 26.7008 51.1404 26.688 51.0124 26.688C50.6796 26.688 50.4556 26.7584 50.3404 26.8992C50.2252 27.04 50.1676 27.2256 50.1676 27.456V37.7216H46.6348Z"
        fill="#A0ABBE"
      />
      <path
        d="M45.609 27.8912V30.4512H39.0298V27.8912H45.609ZM40.2842 37.7216V27.6096C40.2842 26.7989 40.4293 26.1269 40.7194 25.5936C41.0095 25.0603 41.4191 24.6613 41.9482 24.3968C42.4773 24.1323 43.1002 24 43.817 24C44.265 24 44.7002 24.0341 45.1226 24.1024C45.5493 24.1707 45.865 24.2304 46.0698 24.2816L45.5578 26.816C45.4298 26.7776 45.2805 26.7477 45.1098 26.7264C44.9391 26.7008 44.7898 26.688 44.6618 26.688C44.329 26.688 44.105 26.7584 43.9898 26.8992C43.8746 27.04 43.817 27.2256 43.817 27.456V37.7216H40.2842Z"
        fill="#A0ABBE"
      />
      <path
        d="M34.0944 37.9008C33.0277 37.9008 32.1147 37.6896 31.3552 37.2672C30.5957 36.8405 30.0133 36.2475 29.608 35.488C29.2027 34.7243 29 33.8389 29 32.832C29 31.8251 29.2027 30.9419 29.608 30.1824C30.0133 29.4187 30.5957 28.8256 31.3552 28.4032C32.1147 27.9765 33.0277 27.7632 34.0944 27.7632C35.1611 27.7632 36.0741 27.9765 36.8336 28.4032C37.5931 28.8256 38.1755 29.4187 38.5808 30.1824C38.9861 30.9419 39.1888 31.8251 39.1888 32.832C39.1888 33.8389 38.9861 34.7243 38.5808 35.488C38.1755 36.2475 37.5931 36.8405 36.8336 37.2672C36.0741 37.6896 35.1611 37.9008 34.0944 37.9008ZM34.12 35.2896C34.4187 35.2896 34.6768 35.1893 34.8944 34.9888C35.112 34.7883 35.2805 34.5024 35.4 34.1312C35.5195 33.76 35.5792 33.3184 35.5792 32.8064C35.5792 32.2901 35.5195 31.8485 35.4 31.4816C35.2805 31.1104 35.112 30.8245 34.8944 30.624C34.6768 30.4235 34.4187 30.3232 34.12 30.3232C33.8043 30.3232 33.5333 30.4235 33.3072 30.624C33.0811 30.8245 32.9083 31.1104 32.7888 31.4816C32.6693 31.8485 32.6096 32.2901 32.6096 32.8064C32.6096 33.3184 32.6693 33.76 32.7888 34.1312C32.9083 34.5024 33.0811 34.7883 33.3072 34.9888C33.5333 35.1893 33.8043 35.2896 34.12 35.2896Z"
        fill="#A0ABBE"
      />
    </svg>
  )
}

export default LampOffIcon
