import {
  ISmartBlindsDevice,
  ISmartLightBulbDevice,
  ISmartTVDevice,
  ISmartSpeakerDevice,
  ISmartKettleDevice,
  IHVACDevice
} from '../services/iot.service.types'
import { DeviceType } from '../enums/device-type'

export interface ISliderRange {
  min: number
  max: number
}

interface ISliderRanges {
  [DeviceType.SmartBlinds]: Record<
    keyof Pick<ISmartBlindsDevice, 'position'>,
    ISliderRange
  >
  [DeviceType.SmartLightbulb]: Record<
    keyof Pick<ISmartLightBulbDevice, 'brightness' | 'colorTemperature'>,
    ISliderRange
  >
  [DeviceType.SmartTV]: Record<
    keyof Pick<ISmartTVDevice, 'volume'>,
    ISliderRange
  >
  [DeviceType.SmartSpeaker]: Record<
    keyof Pick<ISmartSpeakerDevice, 'volume'>,
    ISliderRange
  >
  [DeviceType.SmartKettle]: Record<
    keyof Pick<ISmartKettleDevice, 'targetTemperature'>,
    ISliderRange
  >
  [DeviceType.HVAC]: Record<keyof Pick<IHVACDevice, 'status'>, ISliderRange>
}

export const SLIDER_RANGES: ISliderRanges = {
  [DeviceType.SmartBlinds]: { position: { min: 1, max: 3 } },
  [DeviceType.SmartLightbulb]: {
    brightness: { min: 0, max: 100 },
    colorTemperature: { min: 2700, max: 6000 }
  },
  [DeviceType.SmartTV]: { volume: { min: 0, max: 100 } },
  [DeviceType.SmartSpeaker]: { volume: { min: 0, max: 100 } },
  [DeviceType.SmartKettle]: { targetTemperature: { min: 0, max: 100 } },
  [DeviceType.HVAC]: { status: { min: 1, max: 3 } }
}
