import { useRef } from 'react'
import clsx from 'clsx'

import classes from './switch.module.scss'

interface Props {
  isSelected: boolean
  onValueChange?: (value: boolean) => void
  className?: string
  disabled?: boolean
}

let nextUniqueId = 0

/**
 * @usage
 * <Switch
 *    isSelected={isToggledOn}
 *    onValueChange={(value) => setIsToggledOn(value)}
 * />
 */
const Switch = ({ isSelected, onValueChange, className, disabled }: Props) => {
  const uniqueId = useRef<string>(`input-${++nextUniqueId}`)

  return (
    <div
      className={`${className} ${classes.rootContainer} ${clsx({
        [classes.disabled]: disabled
      })}`}
    >
      <input
        id={uniqueId.current}
        type="checkbox"
        checked={isSelected}
        onChange={(event) =>
          onValueChange && onValueChange(event.target.checked)
        }
        disabled={disabled}
      />
      <label htmlFor={uniqueId.current}></label>
    </div>
  )
}

export default Switch
