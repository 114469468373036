import { useState, useEffect } from 'react'

import BlindsOffIcon from '../../../../shared/icons/blinds-off-icon/blinds-off-icon'
import BlindsOpenIcon from '../../../../shared/icons/blinds-open-icon/blinds-open-icon'
import BlindsMiddleIcon from '../../../../shared/icons/blinds-middle-icon/blinds-middle-icon'
import BlindsClosedIcon from '../../../../shared/icons/blinds-closed-icon/blinds-closed-icon'
import LampOffIcon from '../../../../shared/icons/lamp-off-icon/lamp-off-icon'
import LampOnIcon from '../../../../shared/icons/lamp-on-icon/lamp-on-icon'
import TVOffIcon from '../../../../shared/icons/tv-off-icon/tv-off-icon'
import TVOnIcon from '../../../../shared/icons/tv-on-icon/tv-on-icon'
import SpeakerOffIcon from '../../../../shared/icons/speaker-off-icon/speaker-off-icon'
import SpeakerOnIcon from '../../../../shared/icons/speaker-on-icon/speaker-on-icon'
import KettleOffIcon from '../../../../shared/icons/kettle-off-icon/kettle-off-icon'
import KettleOnIcon from '../../../../shared/icons/kettle-on-icon/kettle-on-icon'
import ThermostatOffIcon from '../../../../shared/icons/thermostat-off-icon/thermostat-off-icon'
import ThermostatCoolingIcon from '../../../../shared/icons/thermostat-cooling-icon/thermostat-cooling-icon'
import ThermostatEcoIcon from '../../../../shared/icons/thermostat-eco-icon/thermostat-eco-icon'
import ThermostatHeatingIcon from '../../../../shared/icons/thermostat-heating-icon/thermostat-heating-icon'

import FadeAnimation from '../../../../shared/components/fade-animation/fade-animation'

import {
  IDevice,
  ISmartBlindsDevice,
  IHVACDevice
} from '../../../../shared/services/iot.service.types'
import {
  DeviceType,
  SmartBlindPosition,
  HVACStatus,
  TransitionDuration,
  DevicePowerStatus
} from '../../../../shared/enums'
import { iotService } from '../../../../shared/services'
import { SLIDER_RANGES } from '../../../../shared/constants/slider-ranges'

interface Props {
  device: IDevice
}

const DeviceIcon = ({ device }: Props) => {
  const [devicePowerStatus, setDevicePowerStatus] = useState<
    undefined | DevicePowerStatus
  >(undefined)

  useEffect(() => {
    setTimeout(
      () => setDevicePowerStatus(device.powerStatus),
      TransitionDuration.ReallySlow
    )
  }, [device.powerStatus])

  return (
    <>
      {(() => {
        switch (device.type) {
          case DeviceType.SmartBlinds:
            const position = iotService.convertContinuesValueToDiscreteValue(
              SLIDER_RANGES[DeviceType.SmartBlinds].position.min,
              SLIDER_RANGES[DeviceType.SmartBlinds].position.max,
              (device as ISmartBlindsDevice).position
            )

            return (
              <FadeAnimation
                forceRerender={String(devicePowerStatus) + position}
              >
                {(() => {
                  if (
                    !devicePowerStatus ||
                    devicePowerStatus === DevicePowerStatus.Off
                  )
                    return <BlindsOffIcon />
                  switch (position) {
                    case SmartBlindPosition.Open:
                      return <BlindsOpenIcon />
                    case SmartBlindPosition.Middle:
                      return <BlindsMiddleIcon />
                    case SmartBlindPosition.Closed:
                      return <BlindsClosedIcon />
                    default:
                      return <></>
                  }
                })()}
              </FadeAnimation>
            )
          case DeviceType.SmartLightbulb:
            return (
              <FadeAnimation forceRerender={devicePowerStatus}>
                {devicePowerStatus === DevicePowerStatus.On ? (
                  <LampOnIcon />
                ) : (
                  <LampOffIcon />
                )}
              </FadeAnimation>
            )
          case DeviceType.SmartTV:
            return (
              <FadeAnimation forceRerender={devicePowerStatus}>
                {devicePowerStatus === DevicePowerStatus.On ? (
                  <TVOnIcon />
                ) : (
                  <TVOffIcon />
                )}
              </FadeAnimation>
            )
          case DeviceType.SmartSpeaker:
            return (
              <FadeAnimation forceRerender={devicePowerStatus}>
                {devicePowerStatus === DevicePowerStatus.On ? (
                  <SpeakerOnIcon />
                ) : (
                  <SpeakerOffIcon />
                )}
              </FadeAnimation>
            )
          case DeviceType.SmartKettle:
            return (
              <FadeAnimation forceRerender={devicePowerStatus}>
                {devicePowerStatus === DevicePowerStatus.On ? (
                  <KettleOnIcon />
                ) : (
                  <KettleOffIcon />
                )}
              </FadeAnimation>
            )
          case DeviceType.HVAC:
            return (
              <FadeAnimation forceRerender={(device as IHVACDevice)?.status}>
                {(() => {
                  if (
                    !devicePowerStatus ||
                    devicePowerStatus === DevicePowerStatus.Off
                  )
                    return <ThermostatOffIcon />

                  switch ((device as IHVACDevice).status) {
                    case HVACStatus.Cooling:
                      return <ThermostatCoolingIcon />
                    case HVACStatus.Eco:
                      return <ThermostatEcoIcon />
                    case HVACStatus.Heating:
                      return <ThermostatHeatingIcon />
                    default:
                      return <ThermostatOffIcon />
                  }
                })()}
              </FadeAnimation>
            )
        }
      })()}
    </>
  )
}

export default DeviceIcon
