import { ReactNode } from 'react'
import classes from './form-error.module.scss'

interface Props {
  children: ReactNode
}

/**
 * @description The form errors should be wrapped in this component in order to
 * have some consistency over style, and in the future, some functionality can
 * be wrapped here.
 * @usage
 *  <form>
 *    <div>
 *      <Input />
 *      <FormError>{translate('common.forms.errors.required')}</FormError>
 *    </div>
 *  </form>
 */
const FormError = ({ children }: Props) => {
  return (
    <>
      {children && (
        <p className={classes.rootContainer} data-testid="rootContainer">
          <i className="bi bi-exclamation-circle-fill"></i>
          <span>{children}</span>
        </p>
      )}
    </>
  )
}

export default FormError
