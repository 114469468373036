import { useRef, useState, FormEvent, useCallback, useEffect } from 'react'
import { Subject, takeUntil } from 'rxjs'
import clsx from 'clsx'

import Input from '../input/input'
import Button from '../button/button'
import ChatMicrophoneIcon from '../../icons/chat-microphone-icon/chat-microphone-icon'
import Spinner from '../spinner/spinner'

import {
  iotService,
  translate,
  transcribeService,
  audioAnalyserService
} from '../../services'
import { IChatCommand, IChatMessage } from '../../services/iot.service.types'

import classes from './chat.module.scss'

interface Props {
  className: string
}

const Chat = ({ className }: Props) => {
  const waitingForAction = useRef<{
    message: boolean
  }>({ message: false })
  const [initialRender, setInitialRender] = useState(true)
  const [chatMessages, setChatMessages] = useState<IChatMessage[]>([
    { message: '' }
  ])
  const userWasSilent = useRef(true)
  const temporaryChatCommand = useRef<Map<string, string>>(new Map())
  const [chatCommand, setChatCommand] = useState<IChatCommand>({ message: '' })
  const [recordingAudioInProgress, setRecordingAudioInProgress] =
    useState(false)
  const $destroyed = useRef(new Subject<void>())

  const startTranscribing = useCallback(() => {
    setChatCommand({ message: '' })
    userWasSilent.current = true

    audioAnalyserService.subscribeOnSilence({
      onSpeaking: () => {
        userWasSilent.current = false
      }
    })

    transcribeService.subscribeToTranscription(({ transcript, id }) => {
      const filteredTranscription = `${transcript.replace('.', '')} `
      temporaryChatCommand.current.set(id, filteredTranscription)
    })
  }, [])

  const capitalizeFirstLetter = (text: string): string => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
  }

  const stopTranscribing = useCallback(() => {
    audioAnalyserService.unsubscribeFromSilence()

    if (!userWasSilent.current) {
      let currentMsgTmp = ''
      temporaryChatCommand.current.forEach((value) => {
        currentMsgTmp = currentMsgTmp + ' ' + value.trim()
      })

      const _temporaryChatCommand = capitalizeFirstLetter(currentMsgTmp.trim())
      if (!_temporaryChatCommand) {
        console.log({_temporaryChatCommand})
        return
      }

      console.log({_temporaryChatCommand})
      updateStringGradually(
        _temporaryChatCommand,
        (message) => setChatCommand({ message }),
        20
      )
      iotService.sendChatCommand({ message: _temporaryChatCommand })
      waitingForAction.current = { message: true }
    }

    temporaryChatCommand.current = new Map()
    transcribeService.unsubscribeFromTranscription()
    setRecordingAudioInProgress(false)
  }, [temporaryChatCommand])

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      if (!recordingAudioInProgress) {
        startTranscribing()
        setRecordingAudioInProgress(true)
      } else setTimeout(() => stopTranscribing(), 1500)
    },
    [startTranscribing, recordingAudioInProgress, stopTranscribing]
  )

  const updateStringGradually = (
    message: string,
    updatedStringCb: (updatedValue: string, index: number) => void,
    intervalMs: number
  ): Promise<void> => {
    return new Promise((resolve) => {
      if (message) {
        let partialMessage = ''
        let iterationIndex = 0

        const intervalId = setInterval(() => {
          partialMessage += message[iterationIndex]
          updatedStringCb(partialMessage, iterationIndex)
          iterationIndex++

          if (iterationIndex === message.length) {
            clearInterval(intervalId)
            resolve()
            return
          }
        }, intervalMs)
      } else resolve()
    })
  }

  const subscribeToChatMessageUpdates = useCallback(() => {
    const chatMessagesQueue: IChatMessage[] = []
    let updatingInProgress = false
    const updateChatMessages = (newChatMessages: IChatMessage[]) => {
      if (!updatingInProgress && chatMessagesQueue.length) {
        const latestMessage = chatMessagesQueue.shift()
        waitingForAction.current = {
          ...waitingForAction.current,
          message: false
        }

        if (latestMessage) {
          updatingInProgress = true
          latestMessage.message = latestMessage.message.replace('\n', '<br>')

          updateStringGradually(
            latestMessage.message ?? '',
            (message, i) =>
              setChatMessages((_chatMessages) => [
                { message },
                ..._chatMessages.slice(i === 0 ? 0 : 1)
              ]),
            15
          ).then(() => {
            updatingInProgress = false
            updateChatMessages(newChatMessages)
          })
        }
      }
    }

    iotService
      .chatMessageChanges()
      .pipe(takeUntil($destroyed.current))
      .subscribe((newChatMessages) => {
        chatMessagesQueue.push(newChatMessages[0])
        updateChatMessages(newChatMessages)
      })

    return () => {
      $destroyed.current.next()
      $destroyed.current.complete()
    }
  }, [])

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false)
      subscribeToChatMessageUpdates()
    }
  }, [initialRender, subscribeToChatMessageUpdates])

  return (
    <div className={`${classes.rootContainer} ${className}`}>
      <header className={classes.header}>
        <h1>{chatCommand.message}</h1>
      </header>

      <div className={classes.separatorContainer}>
        <div className={classes.separator}></div>
        <p>{translate.common.texts.ai}</p>
        <div className={classes.separator}></div>
      </div>

      <div className={classes.chatMessageAndInputContainer}>
        <ul className={`${classes.chatMessages} no-select`}>
          <li
            className={`${classes.spinnerContainer}  ${clsx({
              [classes.active]: waitingForAction.current.message
            })}`}
          >
            <Spinner className={classes.spinner} />
          </li>

          {chatMessages.map((chatMessage, i) => (
            <li key={i}>
              <p dangerouslySetInnerHTML={{ __html: chatMessage.message }}></p>
            </li>
          ))}
        </ul>

        <div className={classes.inputAndButtonContainer}>
          <form
            className={classes.inputAndButtonInnerContainer}
            onSubmit={handleSubmit}
          >
            <Input
              value={chatCommand.message}
              onChange={(message) => setChatCommand({ message })}
              placeholder={translate.shared.components.chat.inputPlaceholder}
              disabled={true}
            />

            <Button playPulseAnimation={recordingAudioInProgress}>
              <ChatMicrophoneIcon />
            </Button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Chat
