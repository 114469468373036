const ChatMicrophoneIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 2.625C15.1603 2.625 16.2731 3.08594 17.0936 3.90641C17.9141 4.72688 18.375 5.83968 18.375 7V14C18.375 15.1603 17.9141 16.2731 17.0936 17.0936C16.2731 17.9141 15.1603 18.375 14 18.375C13.4255 18.375 12.8566 18.2618 12.3258 18.042C11.795 17.8221 11.3127 17.4999 10.9064 17.0936C10.5001 16.6873 10.1779 16.205 9.95803 15.6742C9.73816 15.1434 9.625 14.5745 9.625 14V6.99999C9.625 5.83967 10.0859 4.72687 10.9064 3.9064C11.7269 3.08594 12.8397 2.625 14 2.625Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 21.875V25.375"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.8269 14.875C21.6115 16.8001 20.6941 18.5784 19.2502 19.8697C17.8063 21.1611 15.9371 21.875 14 21.875C12.0628 21.875 10.1936 21.1611 8.74971 19.8697C7.30579 18.5783 6.38845 16.8001 6.17303 14.875"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ChatMicrophoneIcon
