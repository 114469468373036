const HVACHeatingIndicatorIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00014 16.2001C6.0226 16.2001 3.6001 13.9185 3.6001 11.1141C3.6001 7.20005 9.00018 1.80005 9.00018 1.80005C9.00018 1.80005 14.4001 7.20005 14.4001 11.1141C14.4001 13.9185 11.9777 16.2001 9.00014 16.2001ZM9.00014 16.2001C7.51137 16.2001 6.3001 15.0593 6.3001 13.6571C6.3001 11.7 9.00014 9.00005 9.00014 9.00005C9.00014 9.00005 11.7001 11.7 11.7001 13.6571C11.7001 15.0593 10.4889 16.2001 9.00014 16.2001Z"
        stroke="#FFA800"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default HVACHeatingIndicatorIcon
