import Slider from '../../../../shared/components/slider/slider'

import {
  IDevice,
  IHVACDevice,
  ISmartBlindsDevice,
  ISmartKettleDevice,
  ISmartLightBulbDevice,
  ISmartSpeakerDevice,
  ISmartTVDevice
} from '../../../../shared/services/iot.service.types'
import { DeviceType, HVACStatus } from '../../../../shared/enums'
import { iotService, translate } from '../../../../shared/services'
import { SLIDER_RANGES } from '../../../../shared/constants/slider-ranges'

import classes from './device-slider.module.scss'

interface Props {
  device: IDevice
  onSmartBlindsLevelChange: (position: ISmartBlindsDevice['position']) => void
  onSmartLightBulbBrightnessLevelChange: (
    brightness: ISmartLightBulbDevice['brightness']
  ) => void
  onSmartTVVolumeChange: (volume: ISmartTVDevice['volume']) => void
  onSmartSpeakerVolumeChange: (volume: ISmartSpeakerDevice['volume']) => void
  onSmartKettleTargetTemperatureChange: (
    targetTemperature: ISmartKettleDevice['targetTemperature']
  ) => void
  onHVACDeviceStatusChange: (status: IHVACDevice['status']) => void
  disabled?: boolean
}

const DeviceSlider = ({
  device,
  onSmartBlindsLevelChange,
  onSmartLightBulbBrightnessLevelChange,
  onSmartTVVolumeChange,
  onSmartSpeakerVolumeChange,
  onSmartKettleTargetTemperatureChange,
  onHVACDeviceStatusChange,
  disabled
}: Props) => {
  return (
    <>
      {(() => {
        switch (device.type) {
          case DeviceType.SmartBlinds:
            return (
              <Slider
                label={translate.common.texts.level}
                value={iotService.convertContinuesValueToDiscreteValue(
                  SLIDER_RANGES[DeviceType.SmartBlinds].position.min,
                  SLIDER_RANGES[DeviceType.SmartBlinds].position.max,
                  (device as ISmartBlindsDevice).position
                )}
                onChange={(value) =>
                  onSmartBlindsLevelChange(
                    iotService.convertSmartBlindPositionToContinuesValue(
                      SLIDER_RANGES[DeviceType.SmartBlinds].position.min,
                      SLIDER_RANGES[DeviceType.SmartBlinds].position.max,
                      value
                    )
                  )
                }
                disabled={disabled}
                min={SLIDER_RANGES[DeviceType.SmartBlinds].position.min}
                max={SLIDER_RANGES[DeviceType.SmartBlinds].position.max}
                levels={[
                  { level: translate.common.texts.closed },
                  {
                    level: translate.common.texts.middle,
                    className: classes.smartBlindsMiddleLevelLabel
                  },
                  { level: translate.common.texts.open }
                ]}
                thumbColor="#c5d9ff"
                className={classes.slider}
              />
            )
          case DeviceType.SmartLightbulb:
            return (
              <Slider
                label={translate.common.texts.brightness}
                valueLabel={`${
                  (device as ISmartLightBulbDevice).brightness ?? ''
                }%`}
                value={(device as ISmartLightBulbDevice).brightness}
                onChange={onSmartLightBulbBrightnessLevelChange}
                disabled={disabled}
                min={SLIDER_RANGES[DeviceType.SmartLightbulb].brightness.min}
                max={SLIDER_RANGES[DeviceType.SmartLightbulb].brightness.max}
                levels={[
                  { level: translate.common.texts.off },
                  { level: '100%' }
                ]}
                thumbColor="#faeaaf"
                className={classes.slider}
              />
            )
          case DeviceType.SmartTV:
            return (
              <Slider
                label={translate.common.texts.volume}
                valueLabel={`${(device as ISmartTVDevice).volume ?? ''}%`}
                value={(device as ISmartTVDevice).volume}
                onChange={onSmartTVVolumeChange}
                disabled={disabled}
                min={SLIDER_RANGES[DeviceType.SmartTV].volume.min}
                max={SLIDER_RANGES[DeviceType.SmartTV].volume.max}
                levels={[
                  { level: translate.common.texts.off },
                  { level: '100%' }
                ]}
                thumbColor="#ffd5e9"
                className={classes.slider}
              />
            )
          case DeviceType.SmartSpeaker:
            return (
              <Slider
                label={translate.common.texts.volume}
                valueLabel={`${(device as ISmartSpeakerDevice).volume ?? ''}%`}
                value={(device as ISmartSpeakerDevice).volume}
                onChange={onSmartSpeakerVolumeChange}
                disabled={disabled}
                min={SLIDER_RANGES[DeviceType.SmartSpeaker].volume.min}
                max={SLIDER_RANGES[DeviceType.SmartSpeaker].volume.max}
                levels={[
                  { level: translate.common.texts.off },
                  { level: '100%' }
                ]}
                thumbColor="#ffdecd"
                className={classes.slider}
              />
            )
          case DeviceType.SmartKettle:
            return (
              <Slider
                label={translate.common.texts.temperature}
                valueLabel={`${
                  (device as ISmartKettleDevice).targetTemperature ?? ''
                }ºC`}
                value={(device as ISmartKettleDevice).targetTemperature}
                onChange={onSmartKettleTargetTemperatureChange}
                disabled={disabled}
                min={
                  SLIDER_RANGES[DeviceType.SmartKettle].targetTemperature.min
                }
                max={
                  SLIDER_RANGES[DeviceType.SmartKettle].targetTemperature.max
                }
                levels={[
                  { level: translate.common.texts.off },
                  { level: '100ºC' }
                ]}
                thumbColor="#d0e5e0"
                className={classes.slider}
              />
            )
          case DeviceType.HVAC:
            const getValueLabel = (status: undefined | HVACStatus) => {
              switch (status) {
                case HVACStatus.Cooling:
                  return translate.common.texts.cooling
                case HVACStatus.Eco:
                  return translate.common.texts.eco
                case HVACStatus.Heating:
                  return translate.common.texts.heating
              }
            }

            return (
              <Slider
                value={iotService.convertHVACDeviceStatusToDiscreteValue(
                  (device as IHVACDevice).status
                )}
                label={translate.common.texts.mode}
                valueLabel={getValueLabel((device as IHVACDevice).status)}
                onChange={(status) =>
                  onHVACDeviceStatusChange(
                    iotService.hvacNumbersMapToDeviceStatus[status]
                  )
                }
                disabled={disabled}
                min={SLIDER_RANGES[DeviceType.HVAC].status.min}
                max={SLIDER_RANGES[DeviceType.HVAC].status.max}
                levels={[
                  { level: translate.common.texts.cooling },
                  {
                    level: translate.common.texts.eco
                  },
                  { level: translate.common.texts.heating }
                ]}
                thumbColor="#c5e7f8"
              />
            )
        }
      })()}
    </>
  )
}

export default DeviceSlider
