import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { interval } from 'rxjs'

import { translate, utilsService } from '../../services'
import HomeIcon from '../../icons/home-icon/home-icon'
import ProfilePicture from '../../../assets/images/mixed/profile-picture.png'
import { authService } from '../../services'

import classes from './header.module.scss'
import clsx from 'clsx'

const Header = () => {
  const navigate = useNavigate()
  const getCurrentTimeInMs = () => new Date().getTime()
  const [datetime, setDatetime] = useState(getCurrentTimeInMs())

  useEffect(() => {
    const subscription = interval(1e3).subscribe(() =>
      setDatetime(getCurrentTimeInMs())
    )

    return () => subscription.unsubscribe()
  }, [])

  return (
    <header className={classes.rootContainer}>
      <ul className={classes.ul}>
        <li className="no-select">
          <time dateTime={new Date(datetime).toISOString()}>
            <span className={classes.date}>
              {utilsService.getHumanReadableDate(datetime)}
            </span>
            <span className={classes.separator}>|</span>
            <span className={classes.time}>
              {utilsService.getHumanReadableTime(datetime)}
            </span>
          </time>
        </li>

        <li className="no-select">
          <HomeIcon />
          <p>{translate.shared.components.header.title}</p>
        </li>

        <li
          className={clsx({
            [classes.hidden]: authService.authSession.value === null
          })}
        >
          <div
            className={classes.profileData}
            onClick={() => authService.logout(navigate)}
          >
            <img
              src={ProfilePicture}
              alt={translate.shared.components.header.profileImageAlt}
            />
            <p>Ron Swanson</p>
          </div>
        </li>
      </ul>
    </header>
  )
}

export default Header
