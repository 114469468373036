const ThermostatEcoIcon = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 21H24"
        stroke="#3EC16B"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M54.9754 23C53.847 23 52.7951 22.8453 51.8197 22.5358C50.8634 22.207 50.0219 21.7234 49.2951 21.0851C48.5874 20.4468 48.0232 19.6441 47.6025 18.677C47.2008 17.7099 47 16.5783 47 15.2824C47 14.0058 47.2008 12.913 47.6025 12.0039C48.0232 11.0754 48.5683 10.3211 49.2377 9.74081C49.9071 9.1412 50.6721 8.706 51.5328 8.4352C52.3934 8.14507 53.2732 8 54.1721 8C55.1858 8 56.1038 8.15474 56.9262 8.46422C57.7678 8.77369 58.485 9.19923 59.0779 9.74081C59.6899 10.2824 60.1585 10.9304 60.4836 11.6847C60.8279 12.4391 61 13.2611 61 14.1509C61 14.8085 60.8183 15.3114 60.4549 15.6596C60.0915 16.0077 59.5847 16.2302 58.9344 16.3269L51.8484 17.4004C52.0587 18.0387 52.4891 18.5222 53.1393 18.8511C53.7896 19.1605 54.5355 19.3153 55.377 19.3153C56.1612 19.3153 56.8975 19.2186 57.5861 19.0251C58.2937 18.8124 58.8675 18.5706 59.3074 18.2998C59.6134 18.4932 59.8716 18.764 60.082 19.1122C60.2923 19.4603 60.3975 19.8279 60.3975 20.2147C60.3975 21.0851 59.9959 21.7331 59.1926 22.1586C58.5806 22.4874 57.8921 22.7099 57.127 22.8259C56.362 22.942 55.6448 23 54.9754 23ZM54.1721 11.5977C53.7131 11.5977 53.3115 11.675 52.9672 11.8298C52.6421 11.9845 52.3743 12.1876 52.1639 12.4391C51.9536 12.6712 51.791 12.942 51.6762 13.2515C51.5806 13.5416 51.5232 13.8414 51.5041 14.1509L56.4098 13.3385C56.3525 12.9516 56.1421 12.5648 55.7787 12.1779C55.4153 11.7911 54.8798 11.5977 54.1721 11.5977Z"
        fill="#3EC16B"
      />
      <path
        d="M32 51L32 18"
        stroke="#3EC16B"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M18 28H24"
        stroke="#3EC16B"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M18 35H24"
        stroke="#3EC16B"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M18 42H24"
        stroke="#3EC16B"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <circle
        cx="32"
        cy="58"
        r="5"
        stroke="#3EC16B"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <path
        d="M39.8297 20.0761L39.8297 20.0762C39.8286 20.1136 39.8276 20.1601 39.8276 20.2143V34.9146V46.2243C39.8276 47.4169 40.359 48.4704 41.1231 49.2074C43.523 51.5221 45 54.7303 45 58.2735C45 65.2676 39.2146 71 32 71C24.7854 71 19 65.2676 19 58.2735C19 54.7303 20.477 51.5221 22.8769 49.2074C23.641 48.4704 24.1724 47.4169 24.1724 46.2243V34.9146V20.2143C24.1724 20.1601 24.1714 20.1136 24.1703 20.0762C24.162 19.7766 24.1301 17.0844 25.1777 14.5414C25.6915 13.2942 26.428 12.1749 27.468 11.3696C28.4816 10.5847 29.9076 10 32 10C34.0924 10 35.5184 10.5847 36.532 11.3696C37.572 12.1749 38.3085 13.2942 38.8223 14.5414C39.8699 17.0844 39.838 19.7766 39.8297 20.0761Z"
        stroke="#3EC16B"
        strokeWidth="4"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ThermostatEcoIcon
