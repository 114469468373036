import clsx from 'clsx'

import SmartBlindsSunIcon from '../../../shared/icons/smart-blinds-sun-icon/smart-blinds-sun-icon'
import SmartTVPlayIcon from '../../../shared/icons/smart-tv-play-icon/smart-tv-play-icon'
import SmartSpeakerSpeakerIcon from '../../../shared/icons/smart-speaker-speaker-icon/smart-speaker-speaker-icon'
import SmartKettleHeatingIcon from '../../../shared/icons/smart-kettle-heating-icon/smart-kettle-heating-icon'
import HVACCoolingIndicatorIcon from '../../../shared/icons/hvac-cooling-indicator-icon/hvac-cooling-indicator-icon'
import HVACEcoIndicatorIcon from '../../../shared/icons/hvac-eco-indicator/hvac-eco-indicator'
import HVACHeatingIndicatorIcon from '../../../shared/icons/hvac-heating-indicator/hvac-heating-indicator'
import HVACCoolingIndicatorTwoIcon from '../../../shared/icons/hvac-cooling-indicator-two-icon/hvac-cooling-indicator-two-icon'
import HVACEcoIndicatorTwoIcon from '../../../shared/icons/hvac-eco-two-indicator/hvac-eco-two-indicator'
import HVACHeatingIndicatorTwoIcon from '../../../shared/icons/hvac-heating-two-indicator/hvac-heating-two-indicator'

import FadeAnimation from '../../../shared/components/fade-animation/fade-animation'
import Slider from '../../../shared/components/slider/slider'

import {
  DeviceType,
  HVACStatus,
  SmartKettleStatus
} from '../../../shared/enums'
import {
  IDevice,
  IHVACDevice,
  ISmartBlindsDevice,
  ISmartKettleDevice,
  ISmartLightBulbDevice,
  ISmartSpeakerDevice,
  ISmartTVDevice
} from '../../../shared/services/iot.service.types'
import { translate } from '../../../shared/services'
import { SLIDER_RANGES } from '../../../shared/constants/slider-ranges'

import classes from './device-additional-info-and-controls.module.scss'

interface Props {
  device: IDevice
  onSmartLightBulbColorTemperatureChange: (
    colorTemperature: ISmartLightBulbDevice['colorTemperature']
  ) => void
}

const DeviceAdditionalInfoAndControls = ({
  device,
  onSmartLightBulbColorTemperatureChange
}: Props) => {
  return (
    <div
      className={`${classes.iconAndAdditionalInfo} ${clsx({
        [classes.smartBlinds]: device.type === DeviceType.SmartBlinds,
        [classes.smartLightbulb]: device.type === DeviceType.SmartLightbulb,
        [classes.smartTV]: device.type === DeviceType.SmartTV,
        [classes.smartSpeaker]: device.type === DeviceType.SmartSpeaker,
        [classes.smartKettle]: device.type === DeviceType.SmartKettle,
        [classes.hvac]: device.type === DeviceType.HVAC
      })}`}
    >
      {(() => {
        switch (device.type) {
          case DeviceType.SmartBlinds:
            let position = (device as ISmartBlindsDevice)?.position

            if (position)
              if (position < 50) position = 0
              else if (position > 50 && position < 100) position = 50
              else position = 100

            return (
              <>
                <SmartBlindsSunIcon />
                <p className={classes.deviceStatusText}>
                  {translate.devices.devices.blindsAreUp} {position}%
                </p>
              </>
            )
          case DeviceType.SmartLightbulb:
            return (
              <Slider
                version="thick"
                value={(device as ISmartLightBulbDevice).colorTemperature}
                onChange={onSmartLightBulbColorTemperatureChange}
                disabled={true}
                min={
                  SLIDER_RANGES[DeviceType.SmartLightbulb].colorTemperature.min
                }
                max={
                  SLIDER_RANGES[DeviceType.SmartLightbulb].colorTemperature.max
                }
                levels={[
                  { level: translate.common.texts.warmLight },
                  { level: translate.common.texts.coolLight }
                ]}
              />
            )
          case DeviceType.SmartTV:
            return (
              <>
                <SmartTVPlayIcon />
                <p className={classes.deviceStatusText}>
                  {(device as ISmartTVDevice).currentChannel}
                </p>
              </>
            )
          case DeviceType.SmartSpeaker:
            return (
              <>
                <SmartSpeakerSpeakerIcon />
                <p className={classes.deviceStatusText}>
                  {(device as ISmartSpeakerDevice).currentMedia}
                </p>
              </>
            )
          case DeviceType.SmartKettle:
            return (
              <>
                {(device as ISmartKettleDevice).status ===
                  SmartKettleStatus.Heating && <SmartKettleHeatingIcon />}
                <p className={classes.deviceStatusText}>
                  {(device as ISmartKettleDevice).status}
                </p>
              </>
            )
          case DeviceType.HVAC:
            return (
              <div className={classes.hvacRootContainer}>
                <FadeAnimation forceRerender={(device as IHVACDevice).status}>
                  {(() => {
                    switch ((device as IHVACDevice).status) {
                      case HVACStatus.Cooling:
                        return <HVACCoolingIndicatorIcon />
                      case HVACStatus.Eco:
                        return <HVACEcoIndicatorIcon />
                      case HVACStatus.Heating:
                        return <HVACHeatingIndicatorIcon />
                    }
                  })()}
                </FadeAnimation>

                <p className={classes.temperatureSetpoint}>
                  {(device as IHVACDevice).temperatureSetpoint}ºC
                </p>

                <FadeAnimation
                  forceRerender={(device as IHVACDevice).status}
                  className={classes.statusIndicatorTwoContainer}
                >
                  {(() => {
                    switch ((device as IHVACDevice).status) {
                      case HVACStatus.Cooling:
                        return <HVACCoolingIndicatorTwoIcon />
                      case HVACStatus.Eco:
                        return <HVACEcoIndicatorTwoIcon />
                      case HVACStatus.Heating:
                        return <HVACHeatingIndicatorTwoIcon />
                    }
                  })()}
                </FadeAnimation>

                <p className={classes.statusLabel}>
                  {(() => {
                    switch ((device as IHVACDevice).status) {
                      case HVACStatus.Cooling:
                        return translate.common.texts.cooling
                      case HVACStatus.Eco:
                        return translate.common.texts.eco
                      case HVACStatus.Heating:
                        return translate.common.texts.heating
                    }
                  })()}
                </p>
              </div>
            )
        }
      })()}
    </div>
  )
}

export default DeviceAdditionalInfoAndControls
