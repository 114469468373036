const HVACCoolingIndicatorIcon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 14.4995L16.5885 5.49951"
        stroke="#00D1FF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M16.5891 14.4995L1.00065 5.49951"
        stroke="#00D1FF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M8.79541 19V10V1"
        stroke="#00D1FF"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default HVACCoolingIndicatorIcon
