const HVACCoolingIndicatorTwoIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99742 9.54996C10.0749 9.62807 10.1671 9.69006 10.2686 9.73237C10.3702 9.77468 10.4791 9.79646 10.5891 9.79646C10.6991 9.79646 10.808 9.77468 10.9096 9.73237C11.0111 9.69006 11.1033 9.62807 11.1808 9.54996L13.6808 7.04996C13.7606 6.97226 13.8244 6.87959 13.8685 6.77723C13.9126 6.67488 13.9361 6.56484 13.9376 6.45341C13.9392 6.34198 13.9187 6.23134 13.8775 6.1278C13.8363 6.02426 13.7751 5.92985 13.6974 5.84996C13.6197 5.77007 13.5271 5.70627 13.4247 5.6622C13.3223 5.61813 13.2123 5.59465 13.1009 5.5931C12.9894 5.59155 12.8788 5.61197 12.7753 5.65318C12.6717 5.69439 12.5773 5.7556 12.4974 5.83329L10.5891 7.78329L8.68076 5.83329C8.51942 5.67637 8.30235 5.58997 8.0773 5.5931C7.85226 5.59623 7.63768 5.68862 7.48076 5.84996C7.32384 6.0113 7.23743 6.22837 7.24056 6.45341C7.24369 6.67846 7.33608 6.89304 7.49742 7.04996L9.99742 9.54996ZM12.4974 10.45L10.5891 12.3666L8.68076 10.45C8.51942 10.293 8.30235 10.2066 8.0773 10.2098C7.85226 10.2129 7.63768 10.3053 7.48076 10.4666C7.32384 10.628 7.23743 10.845 7.24056 11.0701C7.24369 11.2951 7.33608 11.5097 7.49742 11.6666L9.99742 14.1666C10.0749 14.2447 10.1671 14.3067 10.2686 14.349C10.3702 14.3913 10.4791 14.4131 10.5891 14.4131C10.6991 14.4131 10.808 14.3913 10.9096 14.349C11.0111 14.3067 11.1033 14.2447 11.1808 14.1666L13.6808 11.6666C13.8377 11.5097 13.9258 11.2969 13.9258 11.075C13.9258 10.853 13.8377 10.6402 13.6808 10.4833C13.5238 10.3264 13.311 10.2382 13.0891 10.2382C12.8672 10.2382 12.6543 10.3264 12.4974 10.4833V10.45Z"
        fill="#00D1FF"
      />
    </svg>
  )
}

export default HVACCoolingIndicatorTwoIcon
