const KettleOnIcon = () => {
  return (
    <svg
      style={{ maxWidth: '8rem' }}
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.1145 13.5366C26.3077 12.6435 27.1008 12 28.0387 12H49.6537C50.5915 12 51.3847 12.6435 51.5778 13.5366C52.8128 19.2471 55.3512 31.512 56.3594 40.2298C57.3037 48.3947 57.6573 59.9498 57.7826 66.0151C57.805 67.0994 56.9311 68 55.8128 68H21.8796C20.7613 68 19.8874 67.0994 19.9098 66.0151C20.035 59.9498 20.3887 48.3947 21.3329 40.2298C22.3411 31.512 24.8796 19.2471 26.1145 13.5366Z"
        stroke="#34C85A"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <path
        d="M20.8462 60H56.8462"
        stroke="#34C85A"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M22.8462 31C22.8462 31 19.7143 28.2426 18.3462 26C17.3251 24.3262 16.5428 21.7198 16.1426 20.2044C15.9796 19.5873 16.45 19 17.0884 19H55.2921C58.2125 19 60.7087 21.1027 61.2049 23.9806L65.3462 48"
        stroke="#34C85A"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default KettleOnIcon
