const ThermostatOffIcon = () => {
  return (
    <svg
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8457 21H24.8457"
        stroke="#A0ABBE"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M32.8457 51L32.8457 18"
        stroke="#A0ABBE"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M18.8457 28H24.8457"
        stroke="#A0ABBE"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M18.8457 35H24.8457"
        stroke="#A0ABBE"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M18.8457 42H24.8457"
        stroke="#A0ABBE"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <circle
        cx="32.8457"
        cy="58"
        r="5"
        stroke="#A0ABBE"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <path
        d="M40.6754 20.0761L40.6754 20.0762C40.6743 20.1136 40.6733 20.1601 40.6733 20.2143V34.9146V46.2243C40.6733 47.4169 41.2047 48.4704 41.9688 49.2074C44.3687 51.5221 45.8457 54.7303 45.8457 58.2735C45.8457 65.2676 40.0603 71 32.8457 71C25.6311 71 19.8457 65.2676 19.8457 58.2735C19.8457 54.7303 21.3227 51.5221 23.7226 49.2074C24.4867 48.4704 25.0181 47.4169 25.0181 46.2243V34.9146V20.2143C25.0181 20.1601 25.0171 20.1136 25.016 20.0762C25.0077 19.7766 24.9758 17.0844 26.0234 14.5414C26.5372 13.2942 27.2737 12.1749 28.3137 11.3696C29.3273 10.5847 30.7533 10 32.8457 10C34.9381 10 36.3641 10.5847 37.3777 11.3696C38.4177 12.1749 39.1542 13.2942 39.668 14.5414C40.7156 17.0844 40.6837 19.7766 40.6754 20.0761Z"
        stroke="#A0ABBE"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <path
        d="M70.7213 13.8912V16.4512H64.1421V13.8912H70.7213ZM65.3965 23.7216V13.6096C65.3965 12.7989 65.5416 12.1269 65.8317 11.5936C66.1218 11.0603 66.5314 10.6613 67.0605 10.3968C67.5896 10.1323 68.2125 10 68.9293 10C69.3773 10 69.8125 10.0341 70.2349 10.1024C70.6616 10.1707 70.9773 10.2304 71.1821 10.2816L70.6701 12.816C70.5421 12.7776 70.3928 12.7477 70.2221 12.7264C70.0514 12.7008 69.9021 12.688 69.7741 12.688C69.4413 12.688 69.2173 12.7584 69.1021 12.8992C68.9869 13.04 68.9293 13.2256 68.9293 13.456V23.7216H65.3965Z"
        fill="#A0ABBE"
      />
      <path
        d="M64.3707 13.8912V16.4512H57.7915V13.8912H64.3707ZM59.0459 23.7216V13.6096C59.0459 12.7989 59.191 12.1269 59.4811 11.5936C59.7712 11.0603 60.1808 10.6613 60.7099 10.3968C61.239 10.1323 61.8619 10 62.5787 10C63.0267 10 63.4619 10.0341 63.8843 10.1024C64.311 10.1707 64.6267 10.2304 64.8315 10.2816L64.3195 12.816C64.1915 12.7776 64.0422 12.7477 63.8715 12.7264C63.7008 12.7008 63.5515 12.688 63.4235 12.688C63.0907 12.688 62.8667 12.7584 62.7515 12.8992C62.6363 13.04 62.5787 13.2256 62.5787 13.456V23.7216H59.0459Z"
        fill="#A0ABBE"
      />
      <path
        d="M52.8561 23.9008C51.7895 23.9008 50.8764 23.6896 50.1169 23.2672C49.3575 22.8405 48.7751 22.2475 48.3697 21.488C47.9644 20.7243 47.7617 19.8389 47.7617 18.832C47.7617 17.8251 47.9644 16.9419 48.3697 16.1824C48.7751 15.4187 49.3575 14.8256 50.1169 14.4032C50.8764 13.9765 51.7895 13.7632 52.8561 13.7632C53.9228 13.7632 54.8359 13.9765 55.5953 14.4032C56.3548 14.8256 56.9372 15.4187 57.3425 16.1824C57.7479 16.9419 57.9505 17.8251 57.9505 18.832C57.9505 19.8389 57.7479 20.7243 57.3425 21.488C56.9372 22.2475 56.3548 22.8405 55.5953 23.2672C54.8359 23.6896 53.9228 23.9008 52.8561 23.9008ZM52.8817 21.2896C53.1804 21.2896 53.4385 21.1893 53.6561 20.9888C53.8737 20.7883 54.0423 20.5024 54.1617 20.1312C54.2812 19.76 54.3409 19.3184 54.3409 18.8064C54.3409 18.2901 54.2812 17.8485 54.1617 17.4816C54.0423 17.1104 53.8737 16.8245 53.6561 16.624C53.4385 16.4235 53.1804 16.3232 52.8817 16.3232C52.566 16.3232 52.2951 16.4235 52.0689 16.624C51.8428 16.8245 51.67 17.1104 51.5505 17.4816C51.4311 17.8485 51.3713 18.2901 51.3713 18.8064C51.3713 19.3184 51.4311 19.76 51.5505 20.1312C51.67 20.5024 51.8428 20.7883 52.0689 20.9888C52.2951 21.1893 52.566 21.2896 52.8817 21.2896Z"
        fill="#A0ABBE"
      />
    </svg>
  )
}

export default ThermostatOffIcon
