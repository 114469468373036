import { useState, useRef, useEffect, useCallback } from 'react'
import { Subject, takeUntil } from 'rxjs'
import clsx from 'clsx'

import Device from './device/device'
import Chat from '../shared/components/chat/chat'

import { iotService } from '../shared/services'
import {
  IDevice,
  ISmartBlindsDevice,
  ISmartLightBulbDevice,
  ISmartTVDevice,
  ISmartSpeakerDevice,
  ISmartKettleDevice,
  IHVACDevice
} from '../shared/services/iot.service.types'
import {
  DeviceType,
  TransitionDuration,
  DevicePowerStatus
} from '../shared/enums'
import { ISliderRange, SLIDER_RANGES } from '../shared/constants/slider-ranges'

import classes from './devices.module.scss'

const Devices = () => {
  const [devices, setDevices] = useState<IDevice[]>([])
  const setDevicesSync = (cb: (_devices: IDevice[]) => IDevice[]) =>
    new Promise((resolve) =>
      setDevices((__devices) => {
        cb(__devices)
        resolve(undefined)
        return __devices
      })
    )
  const [initialRender, setInitialRender] = useState(true)
  const $destroyed = useRef(new Subject<void>())

  const changeDeviceProperty = <DeviceKey extends keyof IDevice>(
    device: IDevice,
    keyValue: Pick<IDevice, DeviceKey>
  ) =>
    setDevices((_devices) => {
      const clonedDevices: IDevice[] = Object.assign([], _devices)
      const deviceIndex = clonedDevices.findIndex(({ id }) => id === device.id)

      clonedDevices[deviceIndex] = {
        ...clonedDevices[deviceIndex],
        ...keyValue
      }

      return clonedDevices
    })

  const getDevicePairContainerId = (deviceId: IDevice['id']) =>
    `devicePairContainer-${deviceId}`

  const handleDevicePowerStatusChange = (
    device: IDevice,
    powerStatus: undefined | DevicePowerStatus
  ) => {
    changeDeviceProperty(device, { powerStatus })
  }

  const handleSmartBlindsLevelChange = (
    device: IDevice,
    position: ISmartBlindsDevice['position']
  ) => {
    changeDeviceProperty(device, { position } as ISmartBlindsDevice)
  }

  const handleSmartLightBulbBrightnessChange = (
    device: IDevice,
    brightness: ISmartLightBulbDevice['brightness']
  ) => {
    changeDeviceProperty(device, {
      brightness
    } as ISmartLightBulbDevice)
  }

  const handleSmartTVVolumeChange = (
    device: IDevice,
    volume: ISmartTVDevice['volume']
  ) => {
    changeDeviceProperty(device, {
      volume
    } as ISmartTVDevice)
  }

  const handleSmartLightBulbColorTemperature = (
    device: IDevice,
    colorTemperature: ISmartLightBulbDevice['colorTemperature']
  ) => {
    changeDeviceProperty(device, {
      colorTemperature
    } as ISmartLightBulbDevice)
  }

  const handleSmartSpeakerVolumeChange = (
    device: IDevice,
    volume: ISmartSpeakerDevice['volume']
  ) => {
    changeDeviceProperty(device, { volume } as ISmartSpeakerDevice)
  }

  const handleSmartKettleTargetTemperatureChange = (
    device: IDevice,
    targetTemperature: ISmartKettleDevice['targetTemperature']
  ) => {
    changeDeviceProperty(device, { targetTemperature } as ISmartKettleDevice)
  }

  const handleHVACDeviceStatusChange = (
    device: IDevice,
    status: IHVACDevice['status']
  ) => {
    changeDeviceProperty(device, {
      status
    } as IHVACDevice)
  }

  const orchestrateAnimations = async (
    animations: Array<{
      fn: () => Promise<void>
      options: {
        awaitUntilNextAnimation: boolean
      }
    }>
  ) => {
    for (const animation of animations)
      if (animation.options.awaitUntilNextAnimation) await animation.fn()
      else animation.fn()
  }

  const handleSettingDevices = useCallback(async (newDevices: IDevice[]) => {
    await orchestrateAnimations([
      // Data that needs no animation
      {
        fn: () =>
          new Promise((resolve) =>
            setDevices((_devices) => {
              const updatedDevices: IDevice[] = []

              for (const newDevice of newDevices) {
                const foundDevice = _devices.find(
                  ({ id }) => id === newDevice.id
                )
                let updatedDevice: IDevice = {}

                if (foundDevice) updatedDevice = { ...foundDevice }

                updatedDevice.id = newDevice.id
                updatedDevice.name = newDevice.name
                updatedDevice.type = newDevice.type

                switch (newDevice.type) {
                  case DeviceType.SmartBlinds:
                    ;(updatedDevice as ISmartBlindsDevice).position = (
                      newDevice as ISmartBlindsDevice
                    ).position
                    break
                  case DeviceType.SmartLightbulb:
                    if (newDevice.powerStatus === DevicePowerStatus.Off) {
                      ;(updatedDevice as ISmartLightBulbDevice).brightness = (
                        newDevice as ISmartLightBulbDevice
                      ).brightness
                      ;(
                        updatedDevice as ISmartLightBulbDevice
                      ).colorTemperature = (
                        newDevice as ISmartLightBulbDevice
                      ).colorTemperature
                    }
                    break
                  case DeviceType.SmartTV:
                    if (newDevice.powerStatus === DevicePowerStatus.Off) {
                      ;(updatedDevice as ISmartTVDevice).volume = (
                        newDevice as ISmartTVDevice
                      ).volume
                    }
                    ;(updatedDevice as ISmartTVDevice).currentChannel = (
                      newDevice as ISmartTVDevice
                    ).currentChannel
                    break
                  case DeviceType.SmartSpeaker:
                    if (newDevice.powerStatus === DevicePowerStatus.Off) {
                      ;(updatedDevice as ISmartSpeakerDevice).volume = (
                        newDevice as ISmartSpeakerDevice
                      ).volume
                    }
                    ;(updatedDevice as ISmartSpeakerDevice).currentMedia = (
                      newDevice as ISmartSpeakerDevice
                    ).currentMedia
                    break
                  case DeviceType.SmartKettle:
                    if (newDevice.powerStatus === DevicePowerStatus.Off) {
                      ;(updatedDevice as ISmartKettleDevice).targetTemperature =
                        (newDevice as ISmartKettleDevice).targetTemperature
                    }
                    ;(updatedDevice as ISmartKettleDevice).status = (
                      newDevice as ISmartKettleDevice
                    ).status
                    break
                  case DeviceType.HVAC:
                    ;(updatedDevice as IHVACDevice).temperatureSetpoint = (
                      newDevice as IHVACDevice
                    ).temperatureSetpoint
                    ;(updatedDevice as IHVACDevice).status = (
                      newDevice as IHVACDevice
                    ).status
                }

                updatedDevices.push(updatedDevice)
              }

              resolve()
              return updatedDevices
            })
          ),
        options: {
          awaitUntilNextAnimation: true
        }
      },
      // Device turning on animation
      {
        fn: () =>
          new Promise(async (resolve) => {
            let iterationCount = 0
            let devicesWithChangedPowerStatusStates: IDevice[] = []

            await setDevicesSync((_devices) => {
              for (const newDevice of newDevices) {
                const deviceChanged = !!_devices.find((device) => {
                  return (
                    newDevice.id === device.id &&
                    newDevice.powerStatus !==
                      (typeof device.powerStatus === 'undefined'
                        ? DevicePowerStatus.Off
                        : device.powerStatus)
                  )
                })

                if (deviceChanged)
                  devicesWithChangedPowerStatusStates.push(newDevice)
              }
              return _devices
            })

            if (!devicesWithChangedPowerStatusStates.length) resolve()

            for (const devicesWithChangedPowerStatusState of devicesWithChangedPowerStatusStates) {
              setTimeout(() => {
                setDevices((_devices) => {
                  const updatedDevices: IDevice[] = Object.assign([], _devices)
                  const foundDeviceIndex = _devices.findIndex(
                    ({ id }) => id === devicesWithChangedPowerStatusState.id
                  )
                  const devicesWithChangedPowerStatusStateIndex =
                    devicesWithChangedPowerStatusStates.findIndex(
                      ({ id }) => id === devicesWithChangedPowerStatusState.id
                    )

                  updatedDevices[foundDeviceIndex].powerStatus =
                    devicesWithChangedPowerStatusState.powerStatus

                  if (
                    devicesWithChangedPowerStatusStateIndex ===
                    devicesWithChangedPowerStatusStates.length - 1
                  )
                    setTimeout(() => {
                      resolve(undefined)
                    }, TransitionDuration.ReallySlow)

                  return updatedDevices
                })
              }, TransitionDuration.ReallySlow * iterationCount++)
            }
          }),
        options: { awaitUntilNextAnimation: true }
      },
      // Device sliders animations
      {
        fn: () =>
          new Promise(async (resolve) => {
            type ISliderFields = Array<
              | keyof Pick<
                  ISmartLightBulbDevice,
                  'brightness' | 'colorTemperature'
                >
              | keyof Pick<ISmartTVDevice, 'volume'>
              | keyof Pick<ISmartSpeakerDevice, 'volume'>
              | keyof Pick<ISmartKettleDevice, 'targetTemperature'>
            >
            let sliderFields: ISliderFields = [
              'brightness',
              'colorTemperature',
              'volume',
              'targetTemperature'
            ]

            const intervalId = setInterval(() => {
              setDevices((_devices) => {
                const updatedDevices: IDevice[] = Object.assign([], _devices)
                let updates: boolean[] = newDevices.map(() => false)
                let iterationIndex = 0

                for (const updatedDevice of updatedDevices) {
                  const newDevice = newDevices.find(
                    ({ id }) => id === updatedDevice.id
                  )

                  for (const sliderField of sliderFields) {
                    if (sliderField in newDevice!) {
                      let previousState =
                        (updatedDevice as any)[sliderField] || 0
                      const currentState = (newDevice as any)[sliderField]
                      const increment = previousState < currentState

                      const incrementUnit =
                        ((
                          (SLIDER_RANGES[updatedDevice.type!] as any)[
                            sliderField
                          ] as ISliderRange
                        ).max -
                          (
                            (SLIDER_RANGES[updatedDevice.type!] as any)[
                              sliderField
                            ] as ISliderRange
                          ).min) /
                        100

                      if (increment && previousState < currentState) {
                        previousState += incrementUnit
                        updates[iterationIndex] = true
                      } else if (!increment && previousState > currentState) {
                        previousState -= incrementUnit
                        updates[iterationIndex] = true
                      } else previousState = currentState

                      if (
                        (increment && previousState > currentState) ||
                        (!increment && previousState < currentState)
                      ) {
                        previousState = currentState
                        updates[iterationIndex] = false
                      }

                      ;(updatedDevice as any)[sliderField] = previousState
                    }
                  }

                  iterationIndex++
                }

                if (updates.every((update) => !update)) {
                  clearInterval(intervalId)
                  resolve()
                }

                return updatedDevices
              })
            }, 3)
          }),
        options: { awaitUntilNextAnimation: true }
      }
    ])
  }, [])

  const subscribeToDeviceUpdates = useCallback(() => {
    iotService
      .deviceChanges()
      .pipe(takeUntil($destroyed.current))
      .subscribe((devices) =>
        handleSettingDevices(JSON.parse(JSON.stringify(devices)))
      )
  }, [handleSettingDevices])

  useEffect(() => {
    subscribeToDeviceUpdates()
    setInitialRender(false)

    return () => {
      $destroyed.current.next()
      // eslint-disable-next-line react-hooks/exhaustive-deps
      $destroyed.current.complete()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section className={classes.rootContainer}>
      <div className={classes.devicesAndChatContainer}>
        <div className={classes.devicesOuterContainer}>
          <div className={classes.devicesContainer}>
            {devices.map((device, i) => (
              <div
                id={getDevicePairContainerId(device.id)}
                className={`${classes.devicePair} ${clsx({
                  [classes.disableTransition]: initialRender,
                  [classes.devicePowerStatusOff]:
                    typeof device.powerStatus === 'undefined'
                      ? DevicePowerStatus.Off
                      : device.powerStatus,
                  [classes.devicePowerStatusOn]:
                    device.powerStatus === DevicePowerStatus.On
                })}`}
                key={i}
              >
                {[1, 2].map((_, j) => (
                  <Device
                    key={i + j}
                    className={j === 1 ? classes.secondDevice : ''}
                    device={device}
                    onPowerStatusChange={(powerStatus) =>
                      handleDevicePowerStatusChange(device, powerStatus)
                    }
                    onSmartBlindsLevelChange={(level) =>
                      handleSmartBlindsLevelChange(device, level)
                    }
                    onSmartLightBulbBrightnessChange={(brightness) =>
                      handleSmartLightBulbBrightnessChange(device, brightness)
                    }
                    onSmartTVVolumeChange={(volume) =>
                      handleSmartTVVolumeChange(device, volume)
                    }
                    onSmartLightBulbColorTemperatureChange={(
                      colorTemperature
                    ) =>
                      handleSmartLightBulbColorTemperature(
                        device,
                        colorTemperature
                      )
                    }
                    onSmartSpeakerVolumeChange={(volume) =>
                      handleSmartSpeakerVolumeChange(device, volume)
                    }
                    onSmartKettleTargetTemperatureChange={(targetTemperature) =>
                      handleSmartKettleTargetTemperatureChange(
                        device,
                        targetTemperature
                      )
                    }
                    onHVACDeviceStatusChange={(status) =>
                      handleHVACDeviceStatusChange(device, status)
                    }
                  />
                ))}
              </div>
            ))}
          </div>
        </div>

        <Chat className={classes.chat} />
      </div>
    </section>
  )
}

export default Devices
