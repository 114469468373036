const SmartKettleHeatingIcon = () => {
  return (
    <svg
      width="28"
      height="27"
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 3.30005L22.2448 4.87976C19.8023 7.07802 20.1134 10.9958 22.8723 12.7809V12.7809C25.7135 14.6194 25.9406 18.6923 23.3215 20.8352L21.36 22.44"
        stroke="black"
        strokeWidth="1.98"
        strokeLinecap="round"
      />
      <path
        d="M15.42 3.30005L13.6648 4.87976C11.2223 7.07802 11.5335 10.9958 14.2923 12.7809V12.7809C17.1335 14.6194 17.3607 18.6923 14.7415 20.8352L12.78 22.44"
        stroke="black"
        strokeWidth="1.98"
        strokeLinecap="round"
      />
      <path
        d="M6.83996 3.30005L5.08473 4.87976C2.64222 7.07802 2.95337 10.9958 5.71226 12.7809V12.7809C8.55343 14.6194 8.78058 18.6923 6.16144 20.8352L4.19996 22.44"
        stroke="black"
        strokeWidth="1.98"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default SmartKettleHeatingIcon
