import { authService } from './auth.service'
import { environment } from '../../environments/environment'
import { awsTranscribe } from '../libs/amazon-transcribe-websocket-static'

export class TranscribeService {
  subscribed = false

  async setCredentials(): Promise<void> {
    const authSession = await authService.fetchAuthSession()

    if (authSession.credentials)
      awsTranscribe.credentials = {
        aws: {
          region: environment.aws.region,
          credentials: {
            accessKeyId: authSession.credentials.accessKeyId,
            secretAccessKey: authSession.credentials.secretAccessKey,
            sessionToken: authSession.credentials.sessionToken
          }
        }
      }
  }

  async subscribeToTranscription(
    cb: (transcription: Record<string, string>) => void
  ): Promise<void> {
    if (!this.subscribed) {
      this.subscribed = true
      await this.setCredentials()
      awsTranscribe.subscribe((transcriptionResults) => {
        if (
          transcriptionResults?.length &&
          transcriptionResults[0].Alternatives.length > 0
        ) {
          cb({transcript: transcriptionResults[0].Alternatives[0].Transcript, id: transcriptionResults[0].ResultId})
        }
      })
    }
  }

  unsubscribeFromTranscription(): void {
    if (this.subscribed) {
      this.subscribed = false
      awsTranscribe.unsubscribe()
    }
  }
}

export const transcribeService = new TranscribeService()
