const HVACEcoIndicatorTwoIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9083 9.55001L9.99998 7.63334L8.09164 9.55001C7.9303 9.70693 7.71324 9.79333 7.48819 9.7902C7.26315 9.78708 7.04856 9.69468 6.89164 9.53334C6.73472 9.372 6.64832 9.15493 6.65145 8.92989C6.65457 8.70484 6.74697 8.49026 6.90831 8.33334L9.40831 5.83334C9.48578 5.75523 9.57795 5.69324 9.6795 5.65093C9.78105 5.60862 9.88997 5.58684 9.99998 5.58684C10.11 5.58684 10.2189 5.60862 10.3205 5.65093C10.422 5.69324 10.5142 5.75523 10.5916 5.83334L13.0916 8.33334C13.2486 8.49026 13.3367 8.70309 13.3367 8.92501C13.3367 9.14692 13.2486 9.35975 13.0916 9.51667C12.9347 9.67359 12.7219 9.76175 12.5 9.76175C12.2781 9.76175 12.0652 9.67359 11.9083 9.51667V9.55001Z"
        fill="#3EC16B"
      />
      <path
        d="M11.9083 11.8272L9.99998 13.7439L8.09164 11.8272C7.9303 11.6703 7.71324 11.5839 7.48819 11.587C7.26315 11.5901 7.04856 11.6825 6.89164 11.8439C6.73472 12.0052 6.64832 12.2223 6.65145 12.4473C6.65457 12.6724 6.74697 12.8869 6.90831 13.0439L9.40831 15.5439C9.48578 15.622 9.57795 15.684 9.6795 15.7263C9.78105 15.7686 9.88997 15.7904 9.99998 15.7904C10.11 15.7904 10.2189 15.7686 10.3205 15.7263C10.422 15.684 10.5142 15.622 10.5916 15.5439L13.0916 13.0439C13.2486 12.8869 13.3367 12.6741 13.3367 12.4522C13.3367 12.2303 13.2486 12.0174 13.0916 11.8605C12.9347 11.7036 12.7219 11.6154 12.5 11.6154C12.2781 11.6154 12.0652 11.7036 11.9083 11.8605V11.8272Z"
        fill="#3EC16B"
      />
    </svg>
  )
}

export default HVACEcoIndicatorTwoIcon
