/**
 * @description This enums aggregate the application routes. Each enum which has
 * the suffix `Paths` is used in defining the page routes.
 */

import { translate } from '../services'

export enum AuthRoutePaths {
  Login = '/login'
}

export enum DeviceRoutePaths {
  Devices = '/devices'
}

export enum WildCardRoutePaths {
  Any = '*'
}

export type RoutePaths = AuthRoutePaths | DeviceRoutePaths

// Used to in naming the web page tabs based on the route paths.
export const PAGE_TITLES: Record<RoutePaths, string> = {
  [AuthRoutePaths.Login]: translate.common.page.title.login,
  [DeviceRoutePaths.Devices]: translate.common.page.title.devices
}

export const WILD_CARD_MATCH_ROUTE_PATH = DeviceRoutePaths.Devices
