const SpeakerOnIcon = () => {
  return (
    <svg
      style={{ maxWidth: '7.385rem' }}
      width="74"
      height="80"
      viewBox="0 0 74 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="37"
        cy="24"
        r="5"
        stroke="#FF8A00"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <circle
        cx="37"
        cy="49"
        r="4"
        stroke="#FF8A00"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <circle
        cx="37"
        cy="49"
        r="12"
        stroke="#FF8A00"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <rect
        x="16"
        y="12"
        width="42"
        height="56"
        rx="2"
        stroke="#FF8A00"
        strokeWidth="4"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SpeakerOnIcon
