const LampOnIcon = () => {
  return (
    <svg
      style={{ maxWidth: '8rem' }}
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35 62H47"
        stroke="#FFCE02"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M38 69H44"
        stroke="#FFCE02"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M60 32C60 38.7534 56.4679 43.8932 50.9569 47.194C49.7673 47.9065 48.9459 49.2051 48.9459 50.6943V54.5H41H33.0541L33.0541 50.6943C33.0541 49.2051 32.2327 47.9065 31.0431 47.194C25.5321 43.8932 22 38.7534 22 32C22 21.5066 30.5066 13 41 13C51.4934 13 60 21.5066 60 32Z"
        stroke="#FFCE02"
        strokeWidth="4"
      />
    </svg>
  )
}

export default LampOnIcon
