const SmartBlindsSunIcon = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7 13.2001C18.7 16.2376 16.2375 18.7001 13.2 18.7001C10.1624 18.7001 7.69995 16.2376 7.69995 13.2001C7.69995 10.1625 10.1624 7.70007 13.2 7.70007C16.2375 7.70007 18.7 10.1625 18.7 13.2001Z"
        stroke="black"
        strokeWidth="1.65"
      />
      <path
        d="M13.1951 3.30005H13.205M13.1958 23.1H13.2056M20.1947 6.19964H20.2046M6.19755 20.2005H6.20742M6.19755 6.20017H6.20742M20.1941 20.201H20.204M23.0902 13.2007H23.1M3.30005 13.2007H3.30992"
        stroke="black"
        strokeWidth="2.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SmartBlindsSunIcon
