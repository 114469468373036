import Switch from '../../../shared/components/switch/switch'

import { IDevice } from '../../../shared/services/iot.service.types'
import { DevicePowerStatus } from '../../../shared/enums'

import classes from './device-name-and-switch.module.scss'

interface Props {
  device: IDevice
  onPowerStatusChange: (value: IDevice['powerStatus']) => void
}

const DeviceNameAndSwitch = ({ device, onPowerStatusChange }: Props) => {
  return (
    <div className={classes.rootContainer}>
      <h1 className={classes.name}>{device.name}</h1>

      <Switch
        isSelected={device.powerStatus === DevicePowerStatus.On}
        onValueChange={(value) =>
          onPowerStatusChange(
            value ? DevicePowerStatus.On : DevicePowerStatus.Off
          )
        }
        disabled={true}
      />
    </div>
  )
}

export default DeviceNameAndSwitch
