import { ILanguage } from './types'

export const EN: ILanguage = {
  common: {
    months: {
      0: 'January',
      1: 'February',
      2: 'March',
      3: 'April',
      4: 'May',
      5: 'June',
      6: 'July',
      7: 'August',
      8: 'September',
      9: 'October',
      10: 'November',
      11: 'December'
    },
    page: {
      title: {
        login: 'Login',
        devices: 'Devices'
      }
    },
    texts: {
      level: 'Level',
      brightness: 'Brightness',
      off: 'off',
      open: 'open',
      middle: 'middle',
      closed: 'closed',
      warmLight: 'warm light',
      coolLight: 'cool light',
      volume: 'Volume',
      temperature: 'Temperature',
      cooling: 'Cooling',
      eco: 'Eco',
      heating: 'Heating',
      ai: 'AI',
      mode: 'Mode',
      login: 'Login',
      username: 'Username',
      password: 'Password'
    }
  },
  shared: {
    components: {
      chat: {
        inputPlaceholder: 'Message Smart Home...'
      },
      header: {
        title: 'IntuitiveHome: AI-Assisted Matter Smart Home',
        profileImageAlt: 'Profile picture'
      }
    }
  },
  auth: {
    login: {
      login: {
        enterNewPassword: 'Enter the new password'
      }
    }
  },
  devices: {
    devices: {
      modeName: 'Morning exercise',
      blindsAreUp: 'Blinds are up'
    }
  }
}
