const ThermostatCoolingIcon = () => {
  return (
    <svg
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8457 21H24.8457"
        stroke="#00D1FF"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M48.8457 21.3695L61.9741 13.7898"
        stroke="#00D1FF"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M61.9746 21.3695L48.8462 13.7898"
        stroke="#00D1FF"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M55.4106 25.1593V17.5796V9.99996"
        stroke="#00D1FF"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M32.8457 51L32.8457 18"
        stroke="#00D1FF"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M18.8457 28H24.8457"
        stroke="#00D1FF"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M18.8457 35H24.8457"
        stroke="#00D1FF"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M18.8457 42H24.8457"
        stroke="#00D1FF"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <circle
        cx="32.8457"
        cy="58"
        r="5"
        stroke="#00D1FF"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <path
        d="M40.6754 20.0761L40.6754 20.0762C40.6743 20.1136 40.6733 20.1601 40.6733 20.2143V34.9146V46.2243C40.6733 47.4169 41.2047 48.4704 41.9688 49.2074C44.3687 51.5221 45.8457 54.7303 45.8457 58.2735C45.8457 65.2676 40.0603 71 32.8457 71C25.6311 71 19.8457 65.2676 19.8457 58.2735C19.8457 54.7303 21.3227 51.5221 23.7226 49.2074C24.4867 48.4704 25.0181 47.4169 25.0181 46.2243V34.9146V20.2143C25.0181 20.1601 25.0171 20.1136 25.016 20.0762C25.0077 19.7766 24.9758 17.0844 26.0234 14.5414C26.5372 13.2942 27.2737 12.1749 28.3137 11.3696C29.3273 10.5847 30.7533 10 32.8457 10C34.9381 10 36.3641 10.5847 37.3777 11.3696C38.4177 12.1749 39.1542 13.2942 39.668 14.5414C40.7156 17.0844 40.6837 19.7766 40.6754 20.0761Z"
        stroke="#00D1FF"
        strokeWidth="4"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ThermostatCoolingIcon
