const HomeIcon = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5002 22.6666C19.4342 23.4964 18.0339 24 16.5002 24C14.9666 24 13.5663 23.4964 12.5002 22.6666"
        stroke="#AEBCCD"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M3.63539 17.6179C3.1647 14.5549 2.92935 13.0235 3.50842 11.6658C4.0875 10.3081 5.37224 9.3792 7.94172 7.52138L9.86152 6.13329C13.0579 3.82218 14.6561 2.66663 16.5002 2.66663C18.3443 2.66663 19.9425 3.82218 23.1389 6.13329L25.0587 7.52138C27.6282 9.3792 28.9129 10.3081 29.492 11.6658C30.0711 13.0235 29.8357 14.5549 29.365 17.6179L28.9636 20.2298C28.2964 24.5719 27.9627 26.7429 26.4055 28.0381C24.8483 29.3333 22.5718 29.3333 18.0186 29.3333H14.9818C10.4286 29.3333 8.15208 29.3333 6.59487 28.0381C5.03766 26.7429 4.70403 24.5719 4.03677 20.2298L3.63539 17.6179Z"
        stroke="#AEBCCD"
        strokeWidth="3"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default HomeIcon
