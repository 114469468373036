const HVACEcoIndicatorIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.1683 2.37914C8.45401 1.60702 9.54609 1.60702 9.8318 2.37914L11.2539 6.22225C11.3437 6.465 11.5351 6.65639 11.7779 6.74622L15.621 8.1683C16.3931 8.45401 16.3931 9.54609 15.621 9.8318L11.7779 11.2539C11.5351 11.3437 11.3437 11.5351 11.2539 11.7779L9.8318 15.621C9.54609 16.3931 8.45401 16.3931 8.1683 15.621L6.74622 11.7779C6.65639 11.5351 6.465 11.3437 6.22225 11.2539L2.37914 9.8318C1.60702 9.54609 1.60702 8.45401 2.37914 8.1683L6.22225 6.74622C6.465 6.65639 6.65639 6.465 6.74622 6.22225L8.1683 2.37914Z"
        stroke="#3EC16B"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default HVACEcoIndicatorIcon
