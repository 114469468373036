import { FormEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Input from '../../shared/components/input/input'
import FormError from '../../shared/components/form-error/form-error'
import Button from '../../shared/components/button/button'

import { authService, translate } from '../../shared/services'
import { DeviceRoutePaths } from '../../shared/enums/route-paths'

import classes from './login.module.scss'

const Login = () => {
  const navigate = useNavigate()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [isLoggingIn, setIsLoggingIn] = useState(false)

  const handleLogin = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setIsLoggingIn(true)
    authService
      .signIn({
        username,
        password
      })
      .then(() => {
        navigate(DeviceRoutePaths.Devices)
      })
      .catch((error) => setError(error.message))
      .finally(() => setIsLoggingIn(false))
  }

  return (
    <section className={classes.rootContainer}>
      <form onSubmit={handleLogin}>
        <div className="row">
          <div className="col-12">
            <h1>{translate.common.texts.login}</h1>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Input
              value={username}
              onChange={setUsername}
              type="text"
              placeholder={translate.common.texts.username}
              disabled={isLoggingIn}
              autofocus={true}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Input
              value={password}
              onChange={setPassword}
              type="password"
              placeholder={translate.common.texts.password}
              disabled={isLoggingIn}
            />

            <FormError>{error}</FormError>
          </div>
        </div>

        <Button disabled={isLoggingIn}>{translate.common.texts.login}</Button>
      </form>
    </section>
  )
}

export default Login
