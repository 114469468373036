/**
 * @description This component is a slightly altered copy of
 * https://codepen.io/niroh/pen/xxBYvGL.
 */

import classes from './spinner.module.scss'

interface Props {
  className?: string
}

const Spinner = ({ className }: Props) => {
  return (
    <div className={`${classes.loadContainer} ${className}`}>
      <div className={classes.loadInner}>
        <div className={classes.loadCircle}>
          <div className={classes.loadCircleInner} />
        </div>
        <div className={classes.loadCircle}>
          <div className={classes.loadCircleInner} />
        </div>
        <div className={classes.loadCircle}>
          <div className={classes.loadCircleInner} />
        </div>
        <div className={classes.loadCircle}>
          <div className={classes.loadCircleInner} />
        </div>
        <div className={classes.loadCircle}>
          <div className={classes.loadCircleInner} />
        </div>
      </div>
    </div>
  )
}

export default Spinner
