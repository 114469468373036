const BlindsOpenIcon = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_2024_367" fill="white">
        <rect x="13" y="16" width="54" height="54" rx="2" />
      </mask>
      <rect
        x="13"
        y="16"
        width="54"
        height="54"
        rx="2"
        stroke="#0055FA"
        strokeWidth="8"
        strokeLinejoin="round"
        mask="url(#path-1-inside-1_2024_367)"
      />
      <mask id="path-2-inside-2_2024_367" fill="white">
        <rect x="19" y="22" width="42" height="42" rx="2" />
      </mask>
      <rect
        x="19"
        y="22"
        width="42"
        height="42"
        rx="2"
        stroke="#0055FA"
        strokeWidth="8"
        strokeLinejoin="round"
        mask="url(#path-2-inside-2_2024_367)"
      />
      <path d="M40 62L40 23" stroke="#0055FA" strokeWidth="4" />
      <rect
        x="12"
        y="12"
        width="56"
        height="6"
        rx="3"
        stroke="#0055FA"
        strokeWidth="4"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BlindsOpenIcon
