const KettleOffIcon = () => {
  return (
    <svg
      style={{ maxWidth: '8rem' }}
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.1145 13.5366C26.3077 12.6435 27.1008 12 28.0387 12H49.6537C50.5915 12 51.3847 12.6435 51.5778 13.5366C52.8128 19.2471 55.3512 31.512 56.3594 40.2298C57.3037 48.3947 57.6573 59.9498 57.7826 66.0151C57.805 67.0994 56.9311 68 55.8128 68H21.8796C20.7613 68 19.8874 67.0994 19.9098 66.0151C20.035 59.9498 20.3887 48.3947 21.3329 40.2298C22.3411 31.512 24.8796 19.2471 26.1145 13.5366Z"
        stroke="#A0ABBE"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <path
        d="M20.8462 60H56.8462"
        stroke="#A0ABBE"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M22.8462 31C22.8462 31 19.7143 28.2426 18.3462 26C17.3251 24.3262 16.5428 21.7198 16.1426 20.2044C15.9796 19.5873 16.45 19 17.0884 19H55.2921C58.2125 19 60.7087 21.1027 61.2049 23.9806L65.3462 48"
        stroke="#A0ABBE"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M49.9596 36.8912V39.4512H43.3804V36.8912H49.9596ZM44.6348 46.7216V36.6096C44.6348 35.7989 44.7798 35.1269 45.07 34.5936C45.3601 34.0603 45.7697 33.6613 46.2988 33.3968C46.8278 33.1323 47.4508 33 48.1676 33C48.6156 33 49.0508 33.0341 49.4732 33.1024C49.8998 33.1707 50.2156 33.2304 50.4204 33.2816L49.9084 35.816C49.7804 35.7776 49.631 35.7477 49.4604 35.7264C49.2897 35.7008 49.1404 35.688 49.0124 35.688C48.6796 35.688 48.4556 35.7584 48.3404 35.8992C48.2252 36.04 48.1676 36.2256 48.1676 36.456V46.7216H44.6348Z"
        fill="#A0ABBE"
      />
      <path
        d="M43.609 36.8912V39.4512H37.0298V36.8912H43.609ZM38.2842 46.7216V36.6096C38.2842 35.7989 38.4293 35.1269 38.7194 34.5936C39.0095 34.0603 39.4191 33.6613 39.9482 33.3968C40.4773 33.1323 41.1002 33 41.817 33C42.265 33 42.7002 33.0341 43.1226 33.1024C43.5493 33.1707 43.865 33.2304 44.0698 33.2816L43.5578 35.816C43.4298 35.7776 43.2805 35.7477 43.1098 35.7264C42.9391 35.7008 42.7898 35.688 42.6618 35.688C42.329 35.688 42.105 35.7584 41.9898 35.8992C41.8746 36.04 41.817 36.2256 41.817 36.456V46.7216H38.2842Z"
        fill="#A0ABBE"
      />
      <path
        d="M32.0944 46.9008C31.0277 46.9008 30.1147 46.6896 29.3552 46.2672C28.5957 45.8405 28.0133 45.2475 27.608 44.488C27.2027 43.7243 27 42.8389 27 41.832C27 40.8251 27.2027 39.9419 27.608 39.1824C28.0133 38.4187 28.5957 37.8256 29.3552 37.4032C30.1147 36.9765 31.0277 36.7632 32.0944 36.7632C33.1611 36.7632 34.0741 36.9765 34.8336 37.4032C35.5931 37.8256 36.1755 38.4187 36.5808 39.1824C36.9861 39.9419 37.1888 40.8251 37.1888 41.832C37.1888 42.8389 36.9861 43.7243 36.5808 44.488C36.1755 45.2475 35.5931 45.8405 34.8336 46.2672C34.0741 46.6896 33.1611 46.9008 32.0944 46.9008ZM32.12 44.2896C32.4187 44.2896 32.6768 44.1893 32.8944 43.9888C33.112 43.7883 33.2805 43.5024 33.4 43.1312C33.5195 42.76 33.5792 42.3184 33.5792 41.8064C33.5792 41.2901 33.5195 40.8485 33.4 40.4816C33.2805 40.1104 33.112 39.8245 32.8944 39.624C32.6768 39.4235 32.4187 39.3232 32.12 39.3232C31.8043 39.3232 31.5333 39.4235 31.3072 39.624C31.0811 39.8245 30.9083 40.1104 30.7888 40.4816C30.6693 40.8485 30.6096 41.2901 30.6096 41.8064C30.6096 42.3184 30.6693 42.76 30.7888 43.1312C30.9083 43.5024 31.0811 43.7883 31.3072 43.9888C31.5333 44.1893 31.8043 44.2896 32.12 44.2896Z"
        fill="#A0ABBE"
      />
    </svg>
  )
}

export default KettleOffIcon
