import DeviceIcon from './device-icon/device-icon'
import DeviceSlider from './device-slider/device-slider'

import {
  IDevice,
  IHVACDevice,
  ISmartBlindsDevice,
  ISmartKettleDevice,
  ISmartLightBulbDevice,
  ISmartSpeakerDevice,
  ISmartTVDevice
} from '../../../shared/services/iot.service.types'

import classes from './device-icon-and-slider.module.scss'

interface Props {
  device: IDevice
  onSmartBlindsLevelChange: (value: ISmartBlindsDevice['position']) => void
  onSmartLightBulbBrightnessLevelChange: (
    brightness: ISmartLightBulbDevice['brightness']
  ) => void
  onSmartTVVolumeChange: (value: ISmartTVDevice['volume']) => void
  onSmartSpeakerVolumeChange: (volume: ISmartSpeakerDevice['volume']) => void
  onSmartKettleTargetTemperatureChange: (
    targetTemperature: ISmartKettleDevice['targetTemperature']
  ) => void
  onHVACDeviceStatusChange: (status: IHVACDevice['status']) => void
}

const DeviceIconAndSlider = ({
  device,
  onSmartBlindsLevelChange,
  onSmartLightBulbBrightnessLevelChange,
  onSmartTVVolumeChange,
  onSmartSpeakerVolumeChange,
  onSmartKettleTargetTemperatureChange,
  onHVACDeviceStatusChange
}: Props) => {
  return (
    <div className={classes.rootContainer}>
      <DeviceIcon device={device} />

      <DeviceSlider
        disabled={true}
        device={device}
        onSmartBlindsLevelChange={onSmartBlindsLevelChange}
        onSmartLightBulbBrightnessLevelChange={
          onSmartLightBulbBrightnessLevelChange
        }
        onSmartTVVolumeChange={onSmartTVVolumeChange}
        onSmartSpeakerVolumeChange={onSmartSpeakerVolumeChange}
        onSmartKettleTargetTemperatureChange={
          onSmartKettleTargetTemperatureChange
        }
        onHVACDeviceStatusChange={onHVACDeviceStatusChange}
      />
    </div>
  )
}

export default DeviceIconAndSlider
