const SmartTVPlayIcon = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.9453 14.9464C22.4454 16.8458 20.0829 18.188 15.3579 20.8724C10.7902 23.4674 8.50632 24.7649 6.66581 24.2434C5.90488 24.0277 5.21158 23.6182 4.65245 23.0541C3.30005 21.6896 3.30005 19.0431 3.30005 13.75C3.30005 8.45686 3.30005 5.8103 4.65245 4.44583C5.21158 3.88171 5.90488 3.47219 6.66581 3.25656C8.50632 2.73501 10.7902 4.03251 15.3579 6.62752C20.0829 9.31189 22.4454 10.6541 22.9453 12.5535C23.1516 13.3375 23.1516 14.1624 22.9453 14.9464Z"
        stroke="black"
        strokeWidth="1.65"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SmartTVPlayIcon
