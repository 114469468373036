import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import { awsService, authService, amplifyService } from './shared/services'
import App from './App'
import reportWebVitals from './reportWebVitals'

import './index.scss'

Promise.all([
  awsService.initGlobalConfig(),
  authService.initGlobalConfig(),
  amplifyService.initGlobalConfig()
]).then(() => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  )

  root.render(
    // <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    // </React.StrictMode>
  )
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(cons1ole.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
