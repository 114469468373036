import DeviceNameAndSwitch from './device-name-and-switch/device-name-and-switch'
import DeviceIconAndSlider from './device-icon-and-slider/device-icon-and-slider'
import DeviceAdditionalInfoAndControls from './device-additional-info-and-controls/device-additional-info-and-controls'

import {
  IDevice,
  IHVACDevice,
  ISmartBlindsDevice,
  ISmartKettleDevice,
  ISmartLightBulbDevice,
  ISmartSpeakerDevice,
  ISmartTVDevice
} from '../../shared/services/iot.service.types'

import classes from './device.module.scss'

interface Props {
  device: IDevice
  onPowerStatusChange: (powerStatus: IDevice['powerStatus']) => void
  onSmartBlindsLevelChange: (position: ISmartBlindsDevice['position']) => void
  onSmartLightBulbBrightnessChange: (
    brightness: ISmartLightBulbDevice['brightness']
  ) => void
  onSmartTVVolumeChange: (volume: ISmartTVDevice['volume']) => void
  onSmartLightBulbColorTemperatureChange: (
    colorTemperature: ISmartLightBulbDevice['colorTemperature']
  ) => void
  onSmartSpeakerVolumeChange: (volume: ISmartSpeakerDevice['volume']) => void
  onSmartKettleTargetTemperatureChange: (
    targetTemperature: ISmartKettleDevice['targetTemperature']
  ) => void
  onHVACDeviceStatusChange: (status: IHVACDevice['status']) => void
  className?: string
}

const Device = ({
  device,
  onPowerStatusChange,
  onSmartBlindsLevelChange,
  onSmartLightBulbBrightnessChange,
  onSmartTVVolumeChange,
  onSmartLightBulbColorTemperatureChange,
  onSmartSpeakerVolumeChange,
  onSmartKettleTargetTemperatureChange,
  onHVACDeviceStatusChange,
  className
}: Props) => {
  return (
    <section className={`${classes.rootContainer} ${className}`}>
      <ul className={classes.ul}>
        <li>
          <DeviceNameAndSwitch
            device={device}
            onPowerStatusChange={onPowerStatusChange}
          />
        </li>

        <li>
          <DeviceIconAndSlider
            device={device}
            onSmartBlindsLevelChange={onSmartBlindsLevelChange}
            onSmartLightBulbBrightnessLevelChange={
              onSmartLightBulbBrightnessChange
            }
            onSmartTVVolumeChange={onSmartTVVolumeChange}
            onSmartSpeakerVolumeChange={onSmartSpeakerVolumeChange}
            onSmartKettleTargetTemperatureChange={
              onSmartKettleTargetTemperatureChange
            }
            onHVACDeviceStatusChange={onHVACDeviceStatusChange}
          />
        </li>

        <li>
          <DeviceAdditionalInfoAndControls
            device={device}
            onSmartLightBulbColorTemperatureChange={
              onSmartLightBulbColorTemperatureChange
            }
          />
        </li>
      </ul>
    </section>
  )
}

export default Device
