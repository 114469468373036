const ThermostatHeatingIcon = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 21H24"
        stroke="#FFA800"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M32 51L32 18"
        stroke="#FFA800"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M18 28H24"
        stroke="#FFA800"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M18 35H24"
        stroke="#FFA800"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M18 42H24"
        stroke="#FFA800"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <circle
        cx="32"
        cy="58"
        r="5"
        stroke="#FFA800"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <path
        d="M39.8297 20.0761L39.8297 20.0762C39.8286 20.1136 39.8276 20.1601 39.8276 20.2143V34.9146V46.2243C39.8276 47.4169 40.359 48.4704 41.1231 49.2074C43.523 51.5221 45 54.7303 45 58.2735C45 65.2676 39.2146 71 32 71C24.7854 71 19 65.2676 19 58.2735C19 54.7303 20.477 51.5221 22.8769 49.2074C23.641 48.4704 24.1724 47.4169 24.1724 46.2243V34.9146V20.2143C24.1724 20.1601 24.1714 20.1136 24.1703 20.0762C24.162 19.7766 24.1301 17.0844 25.1777 14.5414C25.6915 13.2942 26.428 12.1749 27.468 11.3696C28.4816 10.5847 29.9076 10 32 10C34.0924 10 35.5184 10.5847 36.532 11.3696C37.572 12.1749 38.3085 13.2942 38.8223 14.5414C39.8699 17.0844 39.838 19.7766 39.8297 20.0761Z"
        stroke="#FFA800"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M57.539 6.02044C57.1399 5.46649 56.3153 5.46649 55.9162 6.02044L54.4902 7.99977C54.2503 8.33289 53.831 8.48547 53.4331 8.38455L51.0684 7.78491C50.4066 7.61709 49.775 8.1471 49.8253 8.82798L50.0052 11.2608C50.0355 11.6703 49.8125 12.0566 49.4427 12.2351L47.2458 13.2958C46.631 13.5926 46.4878 14.4046 46.9641 14.8938L48.6657 16.6419C48.9521 16.9361 49.0295 17.3754 48.8611 17.7498L47.8599 19.9744C47.5797 20.597 47.992 21.3111 48.6713 21.3798L51.0984 21.625C51.5069 21.6663 51.8486 21.9531 51.9602 22.3482L52.6233 24.6959C52.8088 25.3529 53.5836 25.6349 54.1481 25.2509L56.1651 23.8786C56.5045 23.6477 56.9507 23.6477 57.2901 23.8786L59.3071 25.2509C59.8716 25.6349 60.6464 25.3529 60.832 24.6959L61.495 22.3482C61.6066 21.9531 61.9483 21.6663 62.3568 21.625L64.7839 21.3798C65.4632 21.3111 65.8755 20.597 65.5953 19.9744L64.5942 17.7498C64.4257 17.3754 64.5031 16.9361 64.7895 16.6419L66.4912 14.8938C66.9674 14.4046 66.8242 13.5926 66.2094 13.2958L64.0125 12.2351C63.6428 12.0566 63.4197 11.6703 63.45 11.2608L63.6299 8.82798C63.6802 8.1471 63.0486 7.61709 62.3868 7.78491L60.0221 8.38455C59.6242 8.48547 59.205 8.33289 58.965 7.99977L57.539 6.02044ZM56.9998 20.9995C59.7612 20.9995 61.9998 18.7609 61.9998 15.9995C61.9998 13.2381 59.7612 10.9995 56.9998 10.9995C54.2383 10.9995 51.9998 13.2381 51.9998 15.9995C51.9998 18.7609 54.2383 20.9995 56.9998 20.9995Z"
        fill="#FFA800"
      />
    </svg>
  )
}

export default ThermostatHeatingIcon
