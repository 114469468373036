import classes from './input.module.scss'

interface Props {
  value: string
  onChange?: (value: string) => void
  type?: string
  placeholder?: string
  disabled?: boolean
  autofocus?: boolean
}

const Input = ({
  value,
  onChange,
  type,
  placeholder,
  disabled,
  autofocus
}: Props) => {
  const handleValueChange = (value: string) => {
    if (onChange) onChange(value)
  }

  return (
    <input
      className={classes.input}
      value={value}
      onChange={(event) => handleValueChange(event.target.value)}
      type={type}
      placeholder={placeholder}
      disabled={disabled}
      autoFocus={autofocus}
      autoComplete="off"
    ></input>
  )
}

export default Input
